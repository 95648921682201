import { ReactComponent as UserIcon } from '@assets/icons/24x24/ic-user-24.svg';
import { Box, Center, HStack, VStack } from '@chakra-ui/layout';
import { Icon, Text, Tooltip } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { UseCameraCommandFunctions } from '@hooks/useCameraCommand';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { hasSensorRoleFromActiveProfile, selectActiveUser, selectSessionUuid } from '@redux/authent/authent.selectors';
import { disableAllVideoTracking } from '@redux/global/global.reducer';
import { useIntl } from 'react-intl';

import { ActionEnum } from '@/types/commons/commons.types';
import { SensorFamilyEnum } from '@/types/sensor/sensor.types';
import { CameraStatus, ControlType } from '@/types/sensor/status.types';

type Props = {
  cameraStatus: CameraStatus | null;
  useCamera?: UseCameraCommandFunctions;
  replayMode?: boolean;
  selectedCameraCode: string | null;
};

export default function CameraTakeControlButton({
  useCamera,
  cameraStatus,
  replayMode = false,
  selectedCameraCode,
}: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const user = useAppSelector(selectActiveUser);
  const sessionUuid = useAppSelector(selectSessionUuid);
  const hasControlPriority = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, {
      action: ActionEnum.TAKE_PREMIUM_CONTROL,
      sensorFamily: SensorFamilyEnum.CAMERA,
    }),
  );
  const cameraToken = cameraStatus?.cameraToken ?? null;
  const hasControl = cameraToken?.cameraOperatorUuid === sessionUuid;

  const isControlledBySomeoneElse = cameraToken && !hasControl;
  const canTakeControl = hasControlPriority || cameraStatus?.cameraToken?.controlType !== ControlType.PREMIUM;
  const isDisabled = cameraStatus === null || !canTakeControl;

  const disableTracking = useWithDispatch(disableAllVideoTracking);

  function toggleControl() {
    if (!isDisabled && useCamera) {
      if (hasControl && cameraToken.cameraOperatorUuid) {
        disableTracking(selectedCameraCode);
        useCamera.releaseToken();
      } else {
        useCamera.requestToken();
      }
    }
  }

  function takeControlTooltip() {
    if (!canTakeControl && !replayMode) {
      return formatMessage({ id: 'cameras.cameraMenu.noPriority' });
    }
    return formatMessage(
      {
        id: hasControl ? 'cameras.cameraMenu.releaseControl' : 'cameras.cameraMenu.takeControl',
      },
      { replayMode },
    );
  }

  return (
    <HStack gap={0.25} padding={0.25} userSelect="none">
      <Tooltip isDisabled={cameraStatus === null || (!useCamera && !replayMode)} label={takeControlTooltip()}>
        <VStack
          gap={0.25}
          width="93px"
          backgroundColor={hasControl ? 'neutral.700' : 'neutral.900'}
          cursor={isDisabled || !useCamera ? 'default' : 'pointer'}
          onClick={toggleControl}
        >
          <HStack gap={0.5} padding={1} width="100%">
            <Icon as={UserIcon} width="24px" height="24px" color={hasControl ? 'sky.500' : 'neutral.300'} />
            <Center width="100%">
              <Text fontSize="20px" variant="space" color={hasControl ? 'sky.500' : 'neutral.300'}>
                {user.name.substring(0, 4).trim()}
              </Text>
            </Center>
          </HStack>
          <Box width="100%" height="4px" backgroundColor={hasControl ? 'sky.500' : 'neutral.700'}></Box>
        </VStack>
      </Tooltip>
      <Tooltip
        isDisabled={cameraStatus === null || hasControl}
        label={
          isControlledBySomeoneElse
            ? formatMessage({ id: 'cameras.cameraMenu.hasControl' }, { user: cameraToken.userName, replayMode })
            : formatMessage({ id: 'cameras.cameraMenu.noOneControl' }, { replayMode })
        }
      >
        <VStack gap={0.25} backgroundColor={isControlledBySomeoneElse ? 'neutral.700' : 'neutral.900'} width="93px">
          <HStack gap={0.5} padding={1} width="100%">
            <Icon
              as={UserIcon}
              width="24px"
              height="24px"
              color={isControlledBySomeoneElse ? 'sky.500' : 'neutral.300'}
            />
            <Center width="100%">
              <Text fontSize="20px" variant="space" color={isControlledBySomeoneElse ? 'sky.500' : 'neutral.300'}>
                {isControlledBySomeoneElse ? cameraToken.userName.substring(0, 4).trim() : '—'}
              </Text>
            </Center>
          </HStack>
          <Box width="100%" height="4px" backgroundColor={isControlledBySomeoneElse ? 'sky.500' : 'neutral.700'}></Box>
        </VStack>
      </Tooltip>
    </HStack>
  );
}
