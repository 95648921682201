import { VStack } from '@chakra-ui/react';
import MenuList from '@components/common/menu/MenuList';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useCameraCommand } from '@hooks/useCameraCommand';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { useWithDispatch } from '@hooks/useWithDispatch';
import {
  hasRole,
  hasSensorRoleFromActiveProfile,
  selectActiveUser,
  selectGlobalScreenConfigurationCode,
} from '@redux/authent/authent.selectors';
import { GlobalScreenConfigurationSelectors } from '@redux/config/config.selectors';
import { updateSelectedCameraCode } from '@redux/global/global.reducer';
import { selectTrackingStateOfCameras } from '@redux/global/global.selector';
import {
  centerMapByMapId,
  closePopupByMapId,
  SelectedFeatureTypeEnum,
  updatePopupControlByMapId,
  updateSelectedFeatureKeyByMapId,
} from '@redux/maps/maps.reducer';
import { selectPopupControlByMapId } from '@redux/maps/maps.selectors';
import { selectContext } from '@redux/settings/settings.selectors';
import { selectClosestActiveHealthyCamera, selectHealthyCameraStatuses } from '@redux/situation/monitoring.selectors';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { RootState } from '@redux/store';
import { DroneSelectors } from '@redux/utm/utm.selectors';
import { usePatchContextMutation } from '@services/config/setting.api';
import { useChangeDroneClearanceMutation } from '@services/utm/drone.api';
import { getContextMenuCameraLabel } from '@utils/sensors/camera/camera.utils';
import { getSensorUniqueCode } from '@utils/sensors/sensors.utils';
import { isDrone } from '@utils/target.utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { CameraObjectSizeEnum, Target } from '@/types/c2/c2.types';
import { ActionEnum, Mark, MarkEnum } from '@/types/commons/commons.types';
import { ContextEnum } from '@/types/config/screenConfiguration.types';
import {
  FeatureTypeEnum,
  MapIdEnum,
  PopupDataType,
  PopupDataTypeEnum,
  ShareHoloviewPopupData,
  TargetInfoData,
} from '@/types/map.types';
import { MenuItemOption, MenuItems } from '@/types/menu.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';
import { LocationTypeEnum, SensorFamilyEnum, TargetLocation } from '@/types/sensor/sensor.types';
import { CameraStatus, SensorStatusEnum, TrackingStatusEnum } from '@/types/sensor/status.types';
import { DroneClearance } from '@/types/utm/drone.types';

interface TargetContextMenuProps {
  clickPosition: { x: number; y: number } | null;
  selectedTarget: Target<TargetLocation>;
  onClose: () => void;
}

function TargetContextMenu({ selectedTarget, clickPosition, onClose }: Readonly<TargetContextMenuProps>) {
  const { mapId } = useMapContext();
  const closePopupControl = useWithDispatch(closePopupByMapId);

  const { formatMessage } = useIntl();
  const situationTime = useAppSelector(selectSituationTime);
  const popupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const updateSelectedFeatureKey = useWithDispatch(updateSelectedFeatureKeyByMapId);
  const updateGlobalSelectedCameraCode = useWithDispatch(updateSelectedCameraCode);
  const sensorAction = { action: ActionEnum.COMMAND, sensorFamily: SensorFamilyEnum.CAMERA };
  const accessCamCommand = useAppSelector((state) => hasSensorRoleFromActiveProfile(state, sensorAction));
  const lastLocation = selectedTarget.lastPosition.location;
  const drone =
    useAppSelector((state) => DroneSelectors.selectDroneByCode(state, selectedTarget.droneCode ?? '')) ?? null;
  const canChangeClearance = useAppSelector((state: RootState) => hasRole(state, RoleEnum.CHANGE_CLEARANCE));

  const user = useAppSelector(selectActiveUser);
  const currentGlobalScreenConfigurationCode = useAppSelector(selectGlobalScreenConfigurationCode);
  const currentContext = useAppSelector(selectContext);

  const centerMapView = useWithDispatch(centerMapByMapId);
  const dispatchSelectedFeatureKey = useWithDispatch(updateSelectedFeatureKeyByMapId);

  const healthyLadCameras = useAppSelector(selectHealthyCameraStatuses);
  const closestCameraLAD = useSelectorWithReplayMode(
    selectClosestActiveHealthyCamera,
    selectedTarget.lastPosition.location?.position ?? null,
  );

  const trackingStateOfCameras = useAppSelector(selectTrackingStateOfCameras);

  const useCommand = useCameraCommand({
    cameraStatus: null,
  });
  const [changeDroneClearance] = useChangeDroneClearanceMutation();

  const globalScreenConfiguration =
    useAppSelector((state) =>
      GlobalScreenConfigurationSelectors.selectGlobalScreenConfigurationByCode(
        state,
        currentGlobalScreenConfigurationCode,
      ),
    ) ?? null;
  const ladContextIsPresent = !!globalScreenConfiguration?.screenConfigurations.some(
    (screenConfig) => screenConfig.context === ContextEnum.DOUBT_LAD && screenConfig.gridLayoutCode,
  );

  const position = selectedTarget.lastPosition.location?.position;

  const [patchContext] = usePatchContextMutation();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  function handleClick(option: string, value: MenuItemOption | undefined, depth: number) {
    const temp = structuredClone(selectedOptions).slice(0, depth + 1);
    // click on same place => revert navigation
    if (selectedOptions[depth] === option) {
      setSelectedOptions(temp.slice(0, depth));
    } else {
      // else forward navigation
      setSelectedOptions(temp.toSpliced(depth, 1, option));
      // trigger all potential action linked to menu btn
      if (value?.onAction) {
        value.onAction();
      }
    }
  }

  function openPopup(popupDataType: PopupDataType) {
    updatePopupControl({
      mapId,
      popupControl: {
        ...popupDataType,
        open: true,
        position: { x: clickPosition?.x ?? 500, y: clickPosition?.y ?? 500 },
      },
    });
  }

  function handleOpenTargetInfo() {
    openPopup({
      type: PopupDataTypeEnum.INFO,
      data: {
        type: FeatureTypeEnum.TARGET_ICON,
        targetId: selectedTarget.id,
      } as TargetInfoData,
    });

    updateSelectedFeatureKey({
      mapId,
      selectedFeatureKey: {
        type: SelectedFeatureTypeEnum.TARGET,
        selectedTargetId: selectedTarget.id,
      },
    });

    onClose();
  }

  function handlePopup() {
    if (selectedTarget.identification.mainIdentification === IdentificationEnum.DRONE) {
      openPopup({
        type: PopupDataTypeEnum.HISTORY,
        data: {
          droneId: selectedTarget.id,
          openingTime: situationTime ? new Date(situationTime) : null,
        },
      });
      onClose();
    }
  }

  function startTracking(cameraStatus: CameraStatus) {
    useCommand.sensorTracking(selectedTarget, true, cameraStatus);
    updateGlobalSelectedCameraCode(getSensorUniqueCode(cameraStatus));
  }

  function handleTracking(cameraStatus: CameraStatus) {
    const cameraUniqueCode = getSensorUniqueCode(cameraStatus);
    const isGPSTracking = cameraStatus.status === SensorStatusEnum.TRACKING;
    const isGPSTrackingSelectedTarget =
      isGPSTracking && cameraStatus.trackingStatus.currentTrackedObjectId === selectedTarget.id;
    const isVideoTracking = trackingStateOfCameras.some(
      (value) =>
        value.cameraCode === cameraUniqueCode &&
        value.fluxStates.some((flux) => flux.state === TrackingStatusEnum.ACTIVE),
    );
    const hasRoundOnGoing = cameraStatus.status === SensorStatusEnum.ROUND;

    if (isGPSTrackingSelectedTarget) {
      useCommand.sensorTracking(null, true, cameraStatus);
      useCommand.releaseToken(cameraStatus);
      updateGlobalSelectedCameraCode(null);
    } else if (isGPSTracking || isVideoTracking || hasRoundOnGoing) {
      updatePopupControl({
        mapId: mapId,
        popupControl: {
          type: PopupDataTypeEnum.CONFIRMATION_POPUP,
          open: true,
          position: { x: clickPosition?.x ?? 500, y: clickPosition?.y ?? 500 },
          data: {
            title: formatMessage({ id: 'contextmenu.actions.target.camera.confirmationTitle' }),
            description: formatMessage(
              { id: 'contextmenu.actions.target.camera.confirmationDescription' },
              { isTracking: isGPSTracking || isVideoTracking },
            ),
            submitLabel: formatMessage({ id: 'contextmenu.actions.target.camera.confirmationSubmit' }),
            onSubmit: () => {
              startTracking(cameraStatus);
              closePopupControl(mapId);
            },
          },
        },
      });
    } else {
      startTracking(cameraStatus);
    }
    onClose();
  }

  function centerMap() {
    if (lastLocation?.position) {
      centerMapView({ mapId: MapIdEnum.LAD, position: lastLocation.position });
    }
  }

  function selectTarget() {
    dispatchSelectedFeatureKey({
      mapId: MapIdEnum.LAD,
      selectedFeatureKey: { type: SelectedFeatureTypeEnum.TARGET, selectedTargetId: selectedTarget.id },
    });
  }

  function handleLAD() {
    if (currentContext !== ContextEnum.DOUBT_LAD) {
      patchContext({ login: user.login, context: ContextEnum.DOUBT_LAD });
    }
    centerMap();
    selectTarget();
    if (closestCameraLAD) {
      startTracking(closestCameraLAD);
    }
  }

  function handleLookAt(cameraStatus: CameraStatus) {
    if (closestCameraLAD !== null && lastLocation && lastLocation.position) {
      const altitude = lastLocation.type === LocationTypeEnum.SPATIAL ? lastLocation.altitude : null;
      useCommand.lookAt(
        { latitude: lastLocation.position.latitude, longitude: lastLocation.position.longitude, type: '2D' },
        null,
        null,
        altitude,
        CameraObjectSizeEnum.TARGET,
        true,
        cameraStatus,
      );
      updateGlobalSelectedCameraCode(getSensorUniqueCode(closestCameraLAD.configuration));
      onClose();
    }
  }

  function handleShareTargetHoloview() {
    openPopup({
      type: PopupDataTypeEnum.SHARE_HOLOVIEW,
      data: {
        site: selectedTarget.site,
        trackIds: selectedTarget.trackIds,
      } as ShareHoloviewPopupData,
    });
    onClose();
  }

  function openGpsQrCodePopup() {
    if (selectedTarget.lastPosition.location?.position && situationTime) {
      openPopup({
        type: PopupDataTypeEnum.GPS_QR_CODE,
        data: {
          coordinates: selectedTarget.lastPosition.location.position,
          datetime: situationTime,
        },
      });
      onClose();
    }
  }

  function includes(marks: Mark[], markType: MarkEnum): boolean {
    return marks.some((m) => m.type === markType);
  }

  const handleChangeClearance = (clearance: DroneClearance) => () => {
    if (drone) {
      changeDroneClearance({
        id: drone.id,
        clearance: drone.clearance === clearance ? DroneClearance.CLEARANCE_WITHIN_FLIGHTPLAN : clearance,
        droneCode: drone.code,
      });
    }
  };

  const menuItems: MenuItems = {
    openTargetInfo: {
      label: 'contextmenu.actions.target.targetInfo',
      isDisabled:
        popupControl.open &&
        popupControl.type === PopupDataTypeEnum.INFO &&
        (popupControl.data as TargetInfoData).targetId === selectedTarget.id,
      onAction: handleOpenTargetInfo,
    },
    identifyTarget: {
      label: 'contextmenu.actions.target.identify',
      isHidden: true,
    },
    cameraTrack: {
      label: 'contextmenu.actions.target.camera.cameraTrack',
      isHidden: !accessCamCommand,
      isDisabled: healthyLadCameras.length === 0,
      children: healthyLadCameras.reduce(
        (acc, current) => ({
          ...acc,
          [getSensorUniqueCode(current)]: {
            label: getContextMenuCameraLabel(current),
            shouldNotTranslate: true,
            isActive:
              current.status === SensorStatusEnum.TRACKING &&
              current.trackingStatus.currentTrackedObjectId === selectedTarget.id,
            onAction: () => handleTracking(current),
          },
        }),
        {},
      ),
    },
    pointCUAS: {
      label: 'contextmenu.actions.pointCamera',
      isHidden: !accessCamCommand,
      isDisabled: healthyLadCameras.length === 0 || !position,
      children: healthyLadCameras.reduce(
        (acc, current) => ({
          ...acc,
          [getSensorUniqueCode(current)]: {
            label: getContextMenuCameraLabel(current),
            shouldNotTranslate: true,
            onAction: () => handleLookAt(current),
          },
        }),
        {},
      ),
    },
    doubtCheck: {
      label: `contextmenu.actions.target.c-uas-doubt`,
      isHidden: !accessCamCommand,
      isDisabled: !ladContextIsPresent,
      onAction: handleLAD,
    },
    measure: {
      label: 'contextmenu.actions.target.measure',
      isHidden: true,
    },
    history: {
      label: `contextmenu.actions.target.history`,
      isHidden: !isDrone(selectedTarget),
      isDisabled:
        popupControl.open &&
        popupControl.type === PopupDataTypeEnum.HISTORY &&
        popupControl.data.droneId === selectedTarget.id,
      onAction: handlePopup,
    },
    manageClearance: {
      label: 'contextmenu.actions.target.manageClearance.title',
      isHidden: !canChangeClearance || drone === null,
      children: {
        whiteListDrone: {
          label:
            drone?.clearance !== DroneClearance.FULL_CLEARANCE
              ? 'contextmenu.actions.target.manageClearance.addWhiteListDrone'
              : 'contextmenu.actions.target.manageClearance.removeWhiteListDrone',
          onAction: handleChangeClearance(DroneClearance.FULL_CLEARANCE),
        },
        blackListDrone: {
          label:
            drone?.clearance !== DroneClearance.NO_CLEARANCE
              ? 'contextmenu.actions.target.manageClearance.addBlackListDrone'
              : 'contextmenu.actions.target.manageClearance.removeBlackListDrone',
          onAction: handleChangeClearance(DroneClearance.NO_CLEARANCE),
        },
      },
    },
    shareTarget: {
      label: 'contextmenu.actions.target.broadcastTarget.title',
      children: {
        sendGpsPosition: {
          label: 'contextmenu.actions.target.broadcastTarget.sendGpsPosition',
          isHidden: true,
        },
        displayPositionQrCode: {
          label: 'contextmenu.actions.target.broadcastTarget.displayPositionQrCode',
          isDisabled: !selectedTarget.lastPosition.location?.position,
          onAction: openGpsQrCodePopup,
        },
        shareInMessages: {
          label: 'contextmenu.actions.target.broadcastTarget.shareInMessages',
          isHidden: true,
        },
        shareHoloview: {
          label: 'contextmenu.actions.target.broadcastTarget.shareHoloview',
          isDisabled: includes(selectedTarget.marks, MarkEnum.CRISIS),
          onAction: handleShareTargetHoloview,
        },
        sendTiltAlert: {
          label: 'contextmenu.actions.target.broadcastTarget.sendTiltAlert',
          isHidden: true,
        },
      },
    },
  };

  return (
    <VStack gap={0}>
      <MenuList menuItems={menuItems} depth={0} selectedOptions={selectedOptions} handleClick={handleClick} />
    </VStack>
  );
}

export default TargetContextMenu;
