import { HStack, VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import SensorIcon from '@components/map/infos/sensors/SensorIcon';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectPlatformsNamesByCode } from '@redux/config/config.selectors';
import { selectSensorByUniqueKey } from '@redux/sensors/sensors.selectors';
import { getPlatformsCodesFromMarks } from '@utils/common.utils';
import { getAlertColor, getAlertStatusMessageId } from '@utils/data/notification.utils';
import { getSensorIconKey } from '@utils/map/sensor.utils';
import { isEqual } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertLevelEnum, SensorAlert } from '@/types/data/data.types';
import { SensorStatusEnum } from '@/types/sensor/status.types';

import AlertTime from './AlertTime';

type Props = {
  alert: SensorAlert;
  confirmMode: boolean;
};

export default function SensorAlertCard({ alert, confirmMode }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const lightColor = getAlertColor(alert.level);

  const sensorConfiguration = useAppSelector((state) => selectSensorByUniqueKey(state, alert.identifier));

  const platformsNames = useAppSelector(
    (state) => selectPlatformsNamesByCode(state, getPlatformsCodesFromMarks(alert.marks)),
    isEqual,
  ).join(', ');

  return (
    <>
      <SensorIcon sensorIconName={getSensorIconKey(alert.sensorType, true, false, SensorStatusEnum.DEGRADED)} />
      <VStack gap={0} height="100%" width="100%" paddingRight={2}>
        <HStack gap={2} height="50%" width="100%">
          <Text fontSize="18px" flexShrink={0} width="350px">
            <FormattedMessage id="notification.sensorFailed" />
          </Text>
          <AlertTime startTime={alert.startTime} endTime={alert.endTime} compact={confirmMode} />
        </HStack>
        <HStack gap={1} height="50%" fontWeight="medium" width="100%" fontSize="12px">
          <VStack width="198px" alignItems="start" gap="6px">
            <Text
              backgroundColor={lightColor}
              color={alert.level === AlertLevelEnum.CRITICAL ? 'neutral.white' : 'neutral.black'}
              paddingX={0.5}
              paddingBottom="1px"
              fontSize="12px"
              fontWeight="medium"
            >
              <FormattedMessage id={getAlertStatusMessageId(alert)} />
            </Text>
            <Text fontSize="12px" fontWeight="medium" noOfLines={2}>
              {sensorConfiguration?.name ?? alert.identifier.split('_').at(-1)}
            </Text>
          </VStack>
          <VStack width="198px" alignItems="start" gap="6px">
            <Text
              paddingX={0.5}
              backgroundColor="alertHi.900"
              paddingBottom="1px"
              fontSize="12px"
              fontWeight="medium"
              noOfLines={1}
            >
              {platformsNames || formatMessage({ id: 'components.alert.noPlatform' })}
            </Text>
            <Text fontSize="12px" fontWeight="medium" height="15px">
              {
                //TODO missing information, remove height="15px" when done
              }
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
}
