import { HStack, Text } from '@chakra-ui/layout';
import CustomSelect from '@components/common/inputs/CustomStyledSelect';
import { stringToOption } from '@utils/components/customChipList.utils';
import { formatCoordinatesToString } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { CoordinatesUnitEnum } from '@/types/commons/commons.types';

type CoordinatesBarProps = {
  coordinatesDMS: DmsCoordinates;
  handleUnitChange: (s: string) => void;
  unit: CoordinatesUnitEnum;
  size?: 'xs' | 'sm';
  fixedSize?: boolean;
};

function CoordinatesBar({
  coordinatesDMS,
  handleUnitChange,
  unit,
  size = 'sm',
  fixedSize = false,
}: Readonly<CoordinatesBarProps>) {
  return (
    <CustomSelect
      value={unit}
      key={coordinatesDMS.toString()}
      width={unit === CoordinatesUnitEnum.DECIMAL_DEGREES && !fixedSize ? 400 : 490}
      listWidth={452}
      size={size}
      menuPlacement="top"
      showTooltip={false}
      options={Object.keys(CoordinatesUnitEnum).sort().reverse().map(stringToOption)}
      formatOptionLabel={(option) => (
        <HStack gap={2} width="100%">
          <Text>
            <FormattedMessage id={'controls.toolMenu.latLong'} />
          </Text>
          <Text width="100%" fontWeight="medium">
            {formatCoordinatesToString(coordinatesDMS, option.value as CoordinatesUnitEnum)}
          </Text>
          <Text marginLeft="auto" fontWeight="medium" color={option.value === unit ? 'neutral.white' : 'neutral.300'}>
            <FormattedMessage id={`common.units.${option.value}`} />
          </Text>
        </HStack>
      )}
      isClearable={false}
      onChange={(value) => handleUnitChange(value)}
    />
  );
}

export default memo(CoordinatesBar);
