import { Box, HStack, VStack } from '@chakra-ui/layout';
import { TabPanel, Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { getSensorIconKey } from '@utils/map/sensor.utils';
import { getSensorSubSensorStatusLabelId } from '@utils/sensors/sensors.utils';
import { formatCoordinatesToString } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { FormattedMessage, useIntl } from 'react-intl';

import { SensorUniqueCodes } from '@/types/map.types';
import {
  PositionSubSensor,
  SensorConfiguration,
  SensorTypeEnum,
  SubSensorConfiguration,
  SubSensorTypeEnum,
} from '@/types/sensor/configuration.types';
import { SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

import InfoValue from '../../InfoValue';
import SensorIcon from '../SensorIcon';

type SpecificDescriptionProps = {
  sensorConfiguration: SensorConfiguration | SubSensorConfiguration;
  type: SensorTypeEnum | SubSensorTypeEnum;
};

function SpecificDescription({ sensorConfiguration, type }: Readonly<SpecificDescriptionProps>) {
  const { formatMessage } = useIntl();

  switch (type) {
    case SensorTypeEnum.AUTOMATON: {
      return null;
    }
    default: {
      const notSpecified = formatMessage({ id: 'global.noInformation' });
      return (
        <VStack width="100%" padding={4} gap={2} backgroundColor="neutral.800">
          <HStack width="100%" gap={4} alignItems="start">
            <InfoValue
              label={formatMessage({ id: 'sensors.airportZone' })}
              value={sensorConfiguration.airportZone ?? notSpecified}
            />
            <InfoValue
              label={formatMessage({ id: 'sensors.stateOwnedZone' })}
              value={sensorConfiguration.stateOwnedZone ?? notSpecified}
            />
          </HStack>
          <InfoValue
            label={formatMessage({ id: 'sensors.sector' })}
            value={sensorConfiguration.sector ?? notSpecified}
          />
        </VStack>
      );
    }
  }
}

type DescriptionProps = {
  parentConfiguration: SensorConfiguration | null;
  sensorConfiguration: SensorConfiguration | SubSensorConfiguration;
  sensorStatus: SensorStatus | SubSensorStatus | null;
  sensorUniqueCodes: SensorUniqueCodes;
  sensorType: SensorTypeEnum;
  subSensorType: SubSensorTypeEnum | null;
};

export default function Description({
  parentConfiguration,
  sensorConfiguration,
  sensorStatus,
  sensorType,
  subSensorType,
}: Readonly<DescriptionProps>) {
  const { formatMessage } = useIntl();
  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);

  const notSpecified = formatMessage({ id: 'global.noInformation' });
  const status = sensorStatus?.status;

  const isSensorActive = parentConfiguration
    ? parentConfiguration.active && sensorConfiguration.active
    : sensorConfiguration.active;

  let coordinatesDMS;
  if ('sensorPosition' in sensorConfiguration) {
    coordinatesDMS = new DmsCoordinates(
      sensorConfiguration.sensorPosition.latitude,
      sensorConfiguration.sensorPosition.longitude,
    );
  } else if (sensorStatus) {
    coordinatesDMS = new DmsCoordinates(
      (sensorStatus as SubSensorStatus).position.latitude,
      (sensorStatus as SubSensorStatus).position.longitude,
    );
  } else {
    coordinatesDMS = new DmsCoordinates(
      (sensorConfiguration as PositionSubSensor).position.latitude,
      (sensorConfiguration as PositionSubSensor).position.longitude,
    );
  }

  return (
    <TabPanel>
      <VStack gap={0.25} backgroundColor="neutral.900">
        <HStack width="100%" gap={3} backgroundColor="neutral.700" paddingX={4} paddingY={3}>
          <Box flexShrink={0}>
            <SensorIcon
              sensorIconName={getSensorIconKey(
                subSensorType ?? sensorType,
                isSensorActive,
                sensorConfiguration.maintenance,
                isSensorActive ? status : undefined,
              )}
            />
          </Box>
          <VStack width="100%" gap={0.5} alignItems="start">
            <Text paddingX={0.5} paddingY={0.25} size="lg" backgroundColor="neutral.black" noOfLines={2}>
              {sensorConfiguration.name}
            </Text>
            <Text fontSize="18px">
              <FormattedMessage
                id={getSensorSubSensorStatusLabelId(
                  sensorConfiguration.active && status ? status : null,
                  sensorConfiguration.maintenance,
                )}
              />
            </Text>
          </VStack>
        </HStack>
        <SpecificDescription sensorConfiguration={sensorConfiguration} type={subSensorType ?? sensorType} />
        <VStack width="100%" padding={4} gap={3} backgroundColor="neutral.800">
          <InfoValue label={formatMessage({ id: 'sensors.grid' })} value={notSpecified} />
          <InfoValue
            label={formatMessage({ id: 'sensors.latLong' })}
            value={formatCoordinatesToString(coordinatesDMS, userSettingsUnit)}
          />
          <InfoValue
            label={formatMessage({ id: 'sensors.access' })}
            value={sensorConfiguration.infrastructure ?? notSpecified}
          />
        </VStack>
      </VStack>
    </TabPanel>
  );
}
