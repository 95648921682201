import { ReactComponent as PhoneIcon } from '@assets/icons/40x40/ic-phoneNegative-40.svg';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import CustomCheckbox from '@components/common/inputs/CustomCheckbox';
import CustomSwitch from '@components/common/inputs/CustomSwitch';
import SpecificMaintenance, { MaintenanceVariant } from '@components/map/infos/sensors/maintenance/SpecificMaintenance';
import WebRelayMaintenance from '@components/map/infos/sensors/maintenance/WebRelayMaintenance';
import { useAppSelector } from '@hooks/redux.hooks';
import { hasSensorRoleFromActiveProfile } from '@redux/authent/authent.selectors';
import { selectAllMonitoringEquipmentsBySensorReference } from '@redux/situation/monitoring.selectors';
import {
  useUpdateSensorActivationMutation,
  useUpdateSensorConfigurationMutation,
  useUpdateSensorMaintenanceMutation,
} from '@services/c2/c2.api';
import { sensorFamilyType } from '@utils/sensors/configuration.constants';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { FormattedMessage, useIntl } from 'react-intl';

import { ActionEnum } from '@/types/commons/commons.types';
import { SensorUniqueCodes } from '@/types/map.types';
import {
  EquipmentTypeEnum,
  SensorConfiguration,
  SensorTypeEnum,
  SubSensorConfiguration,
  SubSensorTypeEnum,
} from '@/types/sensor/configuration.types';
import { SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

type Props = {
  parentConfiguration: SensorConfiguration | null;
  sensorConfiguration: SensorConfiguration | SubSensorConfiguration;
  sensorStatus: SensorStatus | SubSensorStatus | null;
  sensorUniqueCodes: SensorUniqueCodes;
  type: SensorTypeEnum | SubSensorTypeEnum;
};

export default function DetailMaintenance({
  parentConfiguration,
  sensorConfiguration,
  sensorStatus,
  sensorUniqueCodes,
  type,
}: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const [patchActivation] = useUpdateSensorActivationMutation();
  const [patchMaintenance] = useUpdateSensorMaintenanceMutation();
  const [updateConfiguration, { isLoading }] = useUpdateSensorConfigurationMutation();

  const canDoSensorMaintenance = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, { action: ActionEnum.MAINTAIN, sensorFamily: sensorFamilyType[type] }),
  );

  const sensorWebRelays = useAppSelector((state) =>
    selectAllMonitoringEquipmentsBySensorReference(state, false, {
      sensorAppCode: sensorConfiguration.appCode,
      sensorCode: sensorConfiguration.code,
      equipmentType: EquipmentTypeEnum.WEB_RELAY,
    }),
  );

  if (!canDoSensorMaintenance) {
    return null;
  }

  const isMaintenanceInherited = parentConfiguration ? parentConfiguration.maintenance : false;
  const isSensorMaintenance = isMaintenanceInherited || sensorConfiguration.maintenance;

  const isSensorActive = parentConfiguration
    ? parentConfiguration.active && sensorConfiguration.active
    : sensorConfiguration.active;

  const isSubSensor = 'parentUniqueCode' in sensorUniqueCodes && !!parentConfiguration;

  const createActivationToast = (success: boolean) => {
    createToast(
      formatMessage(
        {
          id: isSensorActive ? 'contextmenu.actions.disconnectToast' : 'contextmenu.actions.connectToast',
        },
        {
          success,
          subSensorType: formatMessage(
            { id: `contextmenu.actions.subSensorType` },
            {
              subSensorType: type,
            },
          ),
          name: sensorConfiguration.name,
        },
      ),
      success ? ToastStatusEnum.SUCCESS : ToastStatusEnum.ERROR,
    );
  };

  const toggleActive = () => {
    let params;
    if (isSubSensor) {
      params = {
        id: parentConfiguration.id,
        site: parentConfiguration.site,
        appCode: parentConfiguration.appCode,
        active: !isSensorActive,
        type: (sensorConfiguration as SubSensorConfiguration).type,
        codes: [sensorConfiguration.code],
      };
    } else {
      params = {
        id: sensorConfiguration.id,
        site: (sensorConfiguration as SensorConfiguration).site,
        appCode: sensorConfiguration.appCode,
        active: !isSensorActive,
      };
    }

    patchActivation(params)
      .unwrap()
      .then(() => createActivationToast(true))
      .catch(() => createActivationToast(false));
  };

  const createMaintenanceToast = (success: boolean) => {
    createToast(
      formatMessage(
        {
          id: isSensorMaintenance
            ? 'contextmenu.actions.sensorMaintenanceOffToast'
            : 'contextmenu.actions.sensorMaintenanceOnToast',
        },
        {
          success: success,
          subSensorType: formatMessage(
            { id: `contextmenu.actions.subSensorType` },
            {
              subSensorType: type,
            },
          ),
          name: sensorConfiguration.name,
        },
      ),
      success ? ToastStatusEnum.SUCCESS : ToastStatusEnum.ERROR,
    );
  };

  const toggleMaintenance = () => {
    let params;
    if (isSubSensor) {
      params = {
        id: parentConfiguration.id,
        site: parentConfiguration.site,
        appCode: parentConfiguration.appCode,
        maintenance: !isSensorMaintenance,
        type: (sensorConfiguration as SubSensorConfiguration).type,
        codes: [sensorConfiguration.code],
      };
    } else {
      params = {
        id: sensorConfiguration.id,
        site: (sensorConfiguration as SensorConfiguration).site,
        appCode: sensorConfiguration.appCode,
        maintenance: !isSensorMaintenance,
      };
    }

    patchMaintenance(params)
      .unwrap()
      .then(() => createMaintenanceToast(true))
      .catch(() => createMaintenanceToast(false));
  };

  const maintenance = SpecificMaintenance({
    sensorStatus,
    sensorConfiguration,
    sensorUniqueCodes,
    variant: MaintenanceVariant.MONITORING,
  });

  return (
    <VStack flexShrink={0} width="341px" height="100%" gap={0} userSelect="none">
      <VStack
        flexGrow={1}
        flexShrink={1}
        gap={0}
        width="100%"
        padding={3}
        paddingRight={4}
        backgroundColor="neutral.900"
      >
        <VStack gap={3} alignItems="start" width="100%">
          <HStack gap={2} width="100%" justifyContent="space-between">
            <Text size="md" width="100%">
              <FormattedMessage
                id="sensors.maintenance.connectToggle"
                values={{
                  connected: isSensorActive,
                }}
              />
            </Text>
            <CustomSwitch isChecked={isSensorActive} onClick={toggleActive} />
          </HStack>
          <HStack gap={2} width="100%" justifyContent="space-between">
            <Text size="md" width="100%">
              <FormattedMessage
                id="sensors.maintenance.maintenanceToggle"
                values={{
                  maintenance: isSensorMaintenance,
                }}
              />
            </Text>
            <CustomSwitch isChecked={isSensorMaintenance} onClick={toggleMaintenance} />
          </HStack>
        </VStack>
      </VStack>
      {maintenance !== null && (
        <VStack
          flexGrow={2}
          gap={3}
          padding={3}
          paddingRight={4}
          alignItems="start"
          width="100%"
          height="100%"
          backgroundColor="neutral.900"
          borderTop="2px solid"
          borderColor="neutral.black"
        >
          <Text fontSize="16px" color="neutral.200" textTransform="uppercase">
            <FormattedMessage id="components.monitoring.sensorDetail.global.maintenance.maintenance" />
          </Text>
          <VStack width="100%" gap={2}>
            {maintenance}
          </VStack>
        </VStack>
      )}
      {sensorWebRelays.length > 0 && (
        <VStack
          flexGrow={3}
          gap={3}
          padding={3}
          paddingRight={4}
          alignItems="start"
          height="100%"
          width="100%"
          backgroundColor="neutral.900"
          borderTop="2px solid"
          borderColor="neutral.black"
        >
          <Text fontSize="16px" color="neutral.200" textTransform="uppercase">
            <FormattedMessage id="components.monitoring.sensorDetail.global.maintenance.equipment" />
          </Text>
          <VStack width="100%" gap={2}>
            <WebRelayMaintenance gap={2} padding={2} iconColor="neutral.300" monitoringWebRelays={sensorWebRelays} />
          </VStack>
        </VStack>
      )}
      <HStack
        backgroundColor="alertHi.900"
        padding={3}
        paddingRight={4}
        width="100%"
        gap={0}
        flexShrink={0}
        borderTop="2px solid"
        borderColor="neutral.black"
        boxShadow="inset 0 0 0 2px var(--chakra-colors-neutral-900)"
      >
        <VStack width="100%" gap={0}>
          <Text userSelect="none">
            <FormattedMessage id="sensors.emergency" />
          </Text>
          <Text variant="space" width="100%" textAlign="center" fontSize="18px">
            <FormattedMessage id="global.adminNumber" />
          </Text>
        </VStack>
        <Icon as={PhoneIcon} color="alertHi.500" width="40px" height="40px" />
      </HStack>
      {'saveRawData' in sensorConfiguration && (
        <VStack
          padding={3}
          paddingRight={4}
          width="100%"
          gap={3}
          flexShrink={0}
          borderTop="2px solid"
          borderColor="neutral.black"
          alignItems="start"
        >
          <Text fontSize="16px" color="neutral.200" textTransform="uppercase">
            <FormattedMessage id="components.monitoring.sensorDetail.global.maintenance.settings" />
          </Text>
          <CustomCheckbox
            isChecked={sensorConfiguration.saveRawData}
            isDisabled={isLoading}
            isLoading={isLoading}
            onChange={() =>
              updateConfiguration({
                id: sensorConfiguration.id,
                site: sensorConfiguration.site,
                sensor: { ...sensorConfiguration, saveRawData: !sensorConfiguration.saveRawData },
              })
            }
          >
            <Text>
              <FormattedMessage id="components.monitoring.sensorDetail.global.maintenance.saveRawData" />
            </Text>
          </CustomCheckbox>
        </VStack>
      )}
    </VStack>
  );
}
