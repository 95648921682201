import { HStack, Text } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import CustomSwitch from '@components/common/inputs/CustomSwitch';
import { useAppSelector } from '@hooks/redux.hooks';
import { hasRole, hasSensorRoleFromActiveProfile, selectActiveUser } from '@redux/authent/authent.selectors';
import { selectMapFilterByKey } from '@redux/settings/settings.selectors';
import { usePatchMapFiltersMutation } from '@services/config/setting.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { useIntl } from 'react-intl';

import { RoleEnum } from '@/types/authent/roles.types';
import { ActionEnum } from '@/types/commons/commons.types';
import { FilterOption } from '@/types/filters.types';

type Props = {
  filterOption: FilterOption;
  color?: string;
};

function FiltersMenuItem({ filterOption, color }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const user = useAppSelector(selectActiveUser);
  const [updateMapFilters] = usePatchMapFiltersMutation();
  const isChecked = useAppSelector((state) => selectMapFilterByKey(state, filterOption.key));
  const canEditMapFilters = useAppSelector((state) => hasRole(state, RoleEnum.EDIT_MAP_FILTERS));
  const canAccessSensor = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, { action: ActionEnum.ACCESS, sensorFamily: filterOption.sensorFamily }),
  );

  if (filterOption.sensorFamily && !canAccessSensor) {
    return null;
  }

  const enableFilterOption = (filterOption: FilterOption) => {
    updateMapFilters({
      login: user.login,
      mapFilters: { [filterOption.key]: true },
    })
      .unwrap()
      .catch(() => createToast(formatMessage({ id: 'error.mapFiltersError' }), ToastStatusEnum.ERROR));
  };

  const disableFilterOption = (filterOption: FilterOption) => {
    updateMapFilters({
      login: user.login,
      mapFilters: { [filterOption.key]: false },
    })
      .unwrap()
      .catch(() => createToast(formatMessage({ id: 'error.mapFiltersError' }), ToastStatusEnum.ERROR));
  };

  function handleSwitchChange() {
    if (!isChecked) {
      enableFilterOption(filterOption);
    } else {
      disableFilterOption(filterOption);
    }
  }

  return (
    <HStack width="100%" gap={2} alignItems="start">
      <CustomSwitch isChecked={isChecked} onClick={handleSwitchChange} isDisabled={!canEditMapFilters} />
      <HStack gap={2} width="100%" alignSelf="center">
        {filterOption.Image && <Icon as={filterOption.Image} width="24px" height="24px" color={color} />}
        <Text fontSize="16px" fontWeight="medium" color={canEditMapFilters ? undefined : 'neutral.500'}>
          {formatMessage({ id: filterOption.label })}
        </Text>
      </HStack>
    </HStack>
  );
}

export default FiltersMenuItem;
