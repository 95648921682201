import { ReactComponent as ServerIcon } from '@assets/icons/24x24/ic-server-24.svg';
import DataSheetCard from '@components/common/layout/DataSheetCard';
import { useCameraCommand } from '@hooks/useCameraCommand';
import { useIntl } from 'react-intl';

import { ZmerSensorStatus } from '@/types/sensor/status.types';

import { MaintenanceVariant } from './SpecificMaintenance';

type Props = {
  zmerStatus: ZmerSensorStatus | null;
  variant: MaintenanceVariant;
  padding?: number;
  iconColor?: string;
};

export default function ZmerMaintenance({ zmerStatus, variant }: Readonly<Props>) {
  const { formatMessage } = useIntl();

  const useCamera = useCameraCommand({
    cameraStatus: zmerStatus,
  });

  const charmNotConfigured = !zmerStatus?.configuration.charmUsername || !zmerStatus.configuration.charmPassword;
  const charmNotConnected = !zmerStatus?.charmConnected;

  function infoCharm(): string {
    if (charmNotConfigured) {
      return formatMessage({ id: 'cameras.charm.notConfigured' });
    } else if (charmNotConnected) {
      return formatMessage({ id: 'cameras.charm.notConnected' });
    }
    return formatMessage({ id: 'cameras.charm.reboot' });
  }

  return (
    <DataSheetCard
      icon={ServerIcon}
      variant={variant}
      tooltip={infoCharm()}
      description={formatMessage({ id: 'sensors.maintenance.zmer.rebootCharmDescription' })}
      buttonLabel={formatMessage({ id: 'sensors.maintenance.zmer.reboot' })}
      isDisabled={charmNotConfigured || charmNotConnected}
      onClick={useCamera.rebootCharm}
    />
  );
}
