import { ReactComponent as CriticalBorderDecoration } from '@assets/components/panel/alert/critical-alert-border-decoration.svg';
import { Icon } from '@chakra-ui/icon';
import { Box, HStack } from '@chakra-ui/layout';
import SegmentFailureAlertCard from '@components/cockpit/panels/alert/cards/SegmentFailureAlertCard';
import { useAppSelector } from '@hooks/redux.hooks';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { centerMapByMapId } from '@redux/maps/maps.reducer';
import { updateReplayStartPosition, updateReplayStartTime } from '@redux/replay/replay.reducer';
import { selectReplayUuid } from '@redux/replay/replay.selectors';
import { selectContext } from '@redux/settings/settings.selectors';
import { selectOngoingAlertById } from '@redux/situation/situation.selectors';
import { usePatchContextMutation } from '@services/config/setting.api';
import { useUpdateStreamMutation } from '@services/replay/replay.api';
import {
  getAlertColor,
  isPerimeterEffractionAlert,
  isSegmentFailureAlert,
  isSensorFailureAlert,
  isSpaceViolationAlert,
} from '@utils/data/notification.utils';
import { CSSProperties, Dispatch, memo } from 'react';

import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { Alert, AlertLevelEnum, NotificationStatus } from '@/types/data/data.types';
import { MapIdEnum } from '@/types/map.types';

import { AlertsState } from '../AlertsPanel';
import AlertButton from './AlertButton';
import PerimeterAlertCard from './PerimeterAlertCard';
import SensorAlertCard from './SensorAlertCard';
import TargetAlertCard from './TargetAlertCard';

type LiveProps = {
  alert?: never;
  alertId: number;
  state: AlertsState;
  setState: Dispatch<AlertsState>;
  style: CSSProperties;
};

type ArchivedProps = {
  alert: Alert;
  alertId?: never;
  state?: never;
  setState?: never;
  style: CSSProperties;
};

type Props = LiveProps | ArchivedProps;

function BaseAlertCard({ alertId, alert, state, setState, style }: Readonly<Props>) {
  const displayedAlert = useAppSelector((state) => selectOngoingAlertById(state, false, alertId)) ?? alert;
  const centerMapView = useWithDispatch(centerMapByMapId);

  const currentContext = useAppSelector(selectContext);
  const user = useAppSelector(selectActiveUser);
  const [patchContext] = usePatchContextMutation();

  const replayUuid = useAppSelector(selectReplayUuid);
  const [updateStream] = useUpdateStreamMutation();

  const setReplayStartTime = useWithDispatch(updateReplayStartTime);
  const setReplayStartPosition = useWithDispatch(updateReplayStartPosition);

  if (displayedAlert === undefined) {
    return null;
  }

  function onAlertCLick(alert: Alert) {
    if (alert.archivedTime) {
      const position = 'startPosition' in alert ? alert.startPosition : alert.position;

      if (currentContext !== ContextEnum.REPLAY) {
        if (alert.startTime) {
          setReplayStartTime(alert.startTime);
        }
        if (position) {
          setReplayStartPosition(position);
        }
        patchContext({ login: user.login, context: ContextEnum.REPLAY });
      } else {
        const time = new Date(alert.startTime);
        if (replayUuid) {
          updateStream({ uuid: replayUuid, command: { time, speed: 0 } });
        }
        if (position) {
          alert.position && centerMapView({ mapId: MapIdEnum.REPLAY, position });
        }
      }
    } else {
      alert.position && centerMapView({ mapId: MapIdEnum.LIVE, position: alert.position });
    }
  }

  function displayAlert(alert: Alert) {
    if (isSpaceViolationAlert(alert)) {
      return <TargetAlertCard alert={alert} confirmMode={state?.id === alert.id && state.type !== null} />;
    }
    if (isSensorFailureAlert(alert)) {
      return <SensorAlertCard alert={alert} confirmMode={state?.id === alert.id && state.type !== null} />;
    }
    if (isPerimeterEffractionAlert(alert)) {
      return <PerimeterAlertCard alert={alert} confirmMode={state?.id === alert.id && state.type !== null} />;
    }
    if (isSegmentFailureAlert(alert)) {
      return <SegmentFailureAlertCard alert={alert} confirmMode={state?.id === alert.id && state.type !== null} />;
    }
  }

  return (
    <HStack gap={0.25} height="122px" minHeight="122px" width="100%" paddingTop={0.25} style={style}>
      <>
        <HStack
          gap={2}
          width="100%"
          height="100%"
          bgGradient={`linear-gradient(0deg, ${getAlertColor(displayedAlert.level, 800)} 50%, neutral.800 50%)`}
          cursor={displayedAlert.position ? 'pointer' : 'default'}
          onClick={() => onAlertCLick(displayedAlert)}
        >
          {displayedAlert.level === AlertLevelEnum.CRITICAL ? (
            <Icon as={CriticalBorderDecoration} height="100%" flexShrink={0} />
          ) : (
            <Box
              height="100%"
              width="16px"
              flexShrink={0}
              bgGradient={`linear-gradient(90deg, ${getAlertColor(displayedAlert.level)} 50%, transparent 50%)`}
            />
          )}
          {displayAlert(displayedAlert)}
        </HStack>
        {displayedAlert.notificationStatus !== NotificationStatus.ARCHIVED && state && (
          <AlertButton
            id={displayedAlert.id}
            site={displayedAlert.site}
            identifier={displayedAlert.identifier}
            isAck={displayedAlert.ack}
            isConfirmed={displayedAlert.confirmed}
            state={state}
            setState={setState}
          />
        )}
      </>
    </HStack>
  );
}

export default memo(BaseAlertCard, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
