import { HStack, VStack } from '@chakra-ui/layout';
import { Box, Text } from '@chakra-ui/react';
import CustomSlider from '@components/common/inputs/CustomSlider';
import CustomSwitch from '@components/common/inputs/CustomSwitch';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectActiveUser } from '@redux/authent/authent.selectors';
import { selectSettingsState } from '@redux/settings/settings.selectors';
import {
  usePatchAxisInvertedYMutation,
  usePatchLinearJoystickCoefficientMutation,
  usePatchZoomJoystickCameraSensitivityMutation,
} from '@services/config/setting.api';
import { FormattedMessage } from 'react-intl';

export default function CameraSettings() {
  const { login } = useAppSelector(selectActiveUser);
  const settings = useAppSelector(selectSettingsState);

  const [updateJoystickInversion] = usePatchAxisInvertedYMutation();
  const [updateLinearJoystickCoefficient] = usePatchLinearJoystickCoefficientMutation();
  const [updateZoomJoystickCameraSensitivity] = usePatchZoomJoystickCameraSensitivityMutation();

  const handleJoystickInversion = () => {
    updateJoystickInversion({ login: login, axisInvertedY: !settings.axisInvertedY });
  };

  const handleLinearJoystickCoefficient = (value: number) => {
    updateLinearJoystickCoefficient({ login: login, linearJoystickCoefficient: value });
  };
  const handleZoomJoystickCameraSensitivity = (value: number) => {
    updateZoomJoystickCameraSensitivity({ login: login, zoomJoystickCameraSensitivity: value });
  };

  return (
    <VStack width="100%" backgroundColor="neutral.800" gap={4} padding={4} alignItems="start">
      <Text fontSize="22px">
        <FormattedMessage id="components.userSettings.camera.title" />
      </Text>
      <HStack gap={5} width="100%">
        <VStack gap={2} width="50%" alignItems="start" height="100%">
          <Text size="md" color="neutral.300">
            <FormattedMessage id="components.userSettings.camera.linearJoystickCoefficient" />
          </Text>
          <VStack gap={1} width="100%" alignItems="start">
            <HStack justifyContent="space-between" width="100%">
              <Text size="md" color="neutral.white">
                <FormattedMessage id="components.userSettings.camera.cubic" />
              </Text>
              <Text size="md" color="neutral.white" marginRight="48px">
                <FormattedMessage id="components.userSettings.camera.linear" />
              </Text>
            </HStack>
            <Box width="100%">
              <CustomSlider
                value={Math.round(settings.linearJoystickCoefficient * 100)}
                displayedValue={(value) => `${value}%`}
                min={0}
                max={100}
                minLength="100%"
                step={10}
                orientation="horizontal"
                onChange={(value) => handleLinearJoystickCoefficient(value / 100)}
              />
            </Box>
          </VStack>
          <HStack width="100%" gap={2}>
            <CustomSwitch isChecked={settings.axisInvertedY} onClick={handleJoystickInversion} />
            <Text size="md">
              <FormattedMessage id="components.userSettings.camera.axisInvertedY" />
            </Text>
          </HStack>
        </VStack>
        <VStack gap={2} width="50%" alignItems="start" height="100%">
          <Text size="md" color="neutral.300">
            <FormattedMessage id="components.userSettings.camera.zoomJoystickCameraSensitivity" />
          </Text>
          <VStack gap={1} width="100%" alignItems="start">
            <HStack justifyContent="space-between" width="100%">
              <Text size="md" color="neutral.white">
                <FormattedMessage id="components.userSettings.camera.noZoom" />
              </Text>
              <Text size="md" color="neutral.white" marginRight="48px">
                <FormattedMessage id="components.userSettings.camera.zoom" />
              </Text>
            </HStack>
            <Box width="100%">
              <CustomSlider
                value={Math.round(settings.zoomJoystickCameraSensitivity * 100)}
                displayedValue={(value) => `${value}%`}
                min={0}
                max={100}
                minLength="100%"
                step={10}
                orientation="horizontal"
                onChange={(value) => handleZoomJoystickCameraSensitivity(value / 100)}
              />
            </Box>
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
}
