import { Box, Text, VStack } from '@chakra-ui/layout';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { formatCoordinates, toCoordinates } from '@utils/validation/coordinates.utils';
import { format } from 'date-fns';
import DmsCoordinates from 'dms-conversion';
import { useIntl } from 'react-intl';
import QRCode from 'react-qr-code';

import { GpsQrCodeData } from '@/types/map.types';

import DraggableControl from '../controls/DraggableControl';

type Props = {
  top: number;
  left: number;
  onClose: () => void;
} & GpsQrCodeData;

export default function GpsQrCodePopup({ coordinates, datetime, top, left, onClose }: Readonly<Props>) {
  const { formatMessage } = useIntl();
  const queryParameter = encodeURIComponent(`${coordinates.latitude},${coordinates.longitude}`);
  const time = datetime ? format(new Date(datetime), 'HH:mm:ss') : null;
  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);
  const legalCoordinates = toCoordinates([coordinates.longitude, coordinates.latitude])!;
  const { latitude, longitude } = formatCoordinates(
    new DmsCoordinates(legalCoordinates.latitude, legalCoordinates.longitude),
    userSettingsUnit,
  );

  const longitudeText = `${formatMessage({ id: 'global.Longitude' })} : ${longitude}`;
  const latitudeText = `${formatMessage({ id: 'global.Latitude' })} : ${latitude}`;

  return (
    <DraggableControl
      top={top}
      left={left}
      label={formatMessage({ id: 'components.popups.gpsQrCode.label' })}
      height={time ? 360 : 338}
      width={292}
      onClose={onClose}
    >
      <VStack backgroundColor="neutral.800" gap={0}>
        <VStack gap={0.25} alignItems="flex-start" boxSizing="border-box" padding={1} width="100%">
          {time && (
            <Text size="md" color="neutral.200">
              {formatMessage({ id: 'components.popups.gpsQrCode.createdAt' }, { time })}
            </Text>
          )}
          <Text size="md" color="neutral.200">
            {latitudeText}
          </Text>
          <Text size="md" color="neutral.200">
            {longitudeText}
          </Text>
        </VStack>
        <Box backgroundColor="white" border="3px solid white" padding="5px" marginBottom={1} marginX={1}>
          <QRCode value={`https://www.google.com/maps/search/?api=1&query=${queryParameter}`} level="H" />
        </Box>
      </VStack>
    </DraggableControl>
  );
}
