import { Position } from '@turf/helpers';
import { addLeadingZero, addSign } from '@utils/math.utils';
import DmsCoordinates, { Direction } from 'dms-conversion';
import { Feature } from 'geojson';
import { isArray } from 'lodash';
import { LngLat } from 'mapbox-gl';
import * as Yup from 'yup';

import { Coordinates, CoordinatesUnitEnum } from '@/types/commons/commons.types';
import { StrobeLocation } from '@/types/sensor/sensor.types';

import { LATITUDE, LONGITUDE, REQUIRED } from './validation.constant';

export const validateCoordinates: Yup.ObjectSchema<Coordinates> = Yup.object<Coordinates>().shape({
  latitude: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .required(REQUIRED)
    .max(90, LATITUDE)
    .min(-90, LATITUDE),
  longitude: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .required(REQUIRED)
    .max(180, LONGITUDE)
    .min(-180, LONGITUDE),
  type: Yup.string().required(REQUIRED),
}) as Yup.ObjectSchema<Coordinates>;

export const isValidCoordinates = (coordinates: Coordinates | null | undefined): boolean => {
  return !!coordinates && validateCoordinates.isValidSync(coordinates);
};

export const toLonLatArray = (coordinates: Coordinates | null | undefined, validate = true): Position | null => {
  if (!validate || isValidCoordinates(coordinates)) {
    return [coordinates!.longitude, coordinates!.latitude];
  }
  return null;
};

export const isValidLinearLocation = (location: StrobeLocation | undefined | null) => {
  return (
    !!location &&
    location.azimuthAccuracy !== null &&
    location.range != null &&
    location.azimuth !== null &&
    isValidCoordinates(location.center)
  );
};

export const isValidPolygon = (coordinates: Coordinates[]) => {
  return (
    coordinates.length > 2 &&
    coordinates[0].longitude === coordinates[coordinates.length - 1].longitude &&
    coordinates[0].latitude === coordinates[coordinates.length - 1].latitude &&
    coordinates.every((coordinates) => isValidCoordinates(coordinates))
  );
};

export const toFeature = (coords: Coordinates[], id?: string | number): Feature => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [coords.map((coordinate) => [coordinate.longitude, coordinate.latitude])],
    },
    id: id,
    properties: null,
  };
};

export function formatDmsCoordinates(coordinatesDms: DmsCoordinates, precision = 3) {
  const { latitude, longitude } = coordinatesDms.dmsArrays;
  return {
    latitude: `${latitude[0]}°${addLeadingZero(latitude[1])}′${addLeadingZero(latitude[2], precision)}″ ${latitude[3]}`,
    longitude: `${longitude[0]}°${addLeadingZero(longitude[1])}′${addLeadingZero(longitude[2], precision)}″ ${
      longitude[3]
    }`,
  };
}

export function formatCoordinates(
  coordinatesDms: DmsCoordinates,
  unit: CoordinatesUnitEnum,
  options?: { precisionDD?: number; precisionDMS?: number },
): { latitude: string; longitude: string } {
  const { precisionDD, precisionDMS } = { precisionDD: 5, precisionDMS: 3, ...options };
  switch (unit) {
    case CoordinatesUnitEnum.DMS: {
      return formatDmsCoordinates(coordinatesDms, precisionDMS);
    }
    case CoordinatesUnitEnum.DECIMAL_DEGREES:
    default: {
      const latitude = `${addSign(coordinatesDms.latitude.dd.toFixed(precisionDD))} ${coordinatesDms.latitude.hemisphere}`;
      const longitude = `${addSign(coordinatesDms.longitude.dd.toFixed(precisionDD))} ${coordinatesDms.longitude.hemisphere}`;
      return { latitude, longitude };
    }
  }
}

export function formatCoordinatesToString(
  coordinatesDms: DmsCoordinates,
  unit: CoordinatesUnitEnum,
  options?: { precisionDD?: number; precisionDMS?: number },
): string {
  const { latitude, longitude } = formatCoordinates(coordinatesDms, unit, options);
  return `${latitude}, ${longitude}`;
}

export function toCoordinates(
  coordinates: LngLat | Position | [number, number] | null | undefined,
): Coordinates | null {
  const handleLngOverflow = (lng: number) => {
    if (lng > 180) {
      return -180 + (lng % 180);
    }
    if (lng < -180) {
      return 180 + (lng % 180);
    }
    return lng;
  };
  if (isArray(coordinates) && coordinates.length > 1) {
    return { latitude: coordinates[1], longitude: handleLngOverflow(coordinates[0]), type: '2D' };
  }
  if (coordinates && 'lat' in coordinates && 'lng' in coordinates) {
    return { latitude: coordinates.lat, longitude: handleLngOverflow(coordinates.lng), type: '2D' };
  }
  return null;
}

function getNsewAndMax(coordinate: number, longOrLat: 'long' | 'lat'): { max: 90 | 180; nsew: Direction } {
  if (longOrLat === 'long') {
    if (coordinate >= 0) {
      return { max: 180, nsew: 'E' };
    } else {
      return { max: 180, nsew: 'W' };
    }
  } else if (coordinate >= 0) {
    return { max: 90, nsew: 'N' };
  } else {
    return { max: 90, nsew: 'S' };
  }
}

export function toDms(
  coordinate: number,
  longOrLat: 'long' | 'lat',
  epsilon = 10e-6,
): [number, number, number, Direction] {
  if (isNaN(coordinate)) {
    return [NaN, NaN, NaN, 'N'];
  }
  const absoluteCoordinate = Math.abs(coordinate);
  const { max, nsew } = getNsewAndMax(coordinate, longOrLat);
  if (absoluteCoordinate >= max) {
    return [max, 0, 0, nsew];
  }
  const roundedCoordinate = Math.round(absoluteCoordinate);
  if (Math.abs(roundedCoordinate - absoluteCoordinate) < epsilon * 0.01) {
    return [roundedCoordinate, 0, 0, nsew];
  }
  const degrees = Math.floor(absoluteCoordinate);
  const minutesWithRest = (absoluteCoordinate - degrees) * 60;
  const minutesWithRestRounded = Math.round(minutesWithRest);
  if (Math.abs(minutesWithRest - minutesWithRestRounded) < epsilon) {
    return [degrees, minutesWithRestRounded, 0, nsew];
  }
  const minutes = Math.floor(minutesWithRest);
  const seconds = (minutesWithRest - minutes) * 60;
  return [degrees, minutes, seconds, nsew];
}

export function toDD(degrees: number, minutes: number, seconds: number, nsew: Direction) {
  return (degrees + minutes / 60 + seconds / 3600) * (nsew === 'W' || nsew === 'S' ? -1 : 1);
}
