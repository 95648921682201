import { SensorTypeEnum, SubSensorTypeEnum } from '@/types/sensor/configuration.types';
import { SensorFamilyEnum } from '@/types/sensor/sensor.types';
import { SensorStatusEnum } from '@/types/sensor/status.types';

export const PERIMETER_SECTION_TEXT_OFFSET_L = [0, 45];
export const SENSOR_LABEL_TEXT_SIZE = 14;
export const SENSOR_MAX_ZOOM_RANGE_LIMIT = 15;
export const SENSOR_MIN_ZOOM_RANGE_LIMIT = 13;
export const SECTION_ENDS_ARBITRARY_LENGTH = 0.05;
export const WIDTH_SCALING_FACTOR = 24;

export enum SensorColor {
  ORANGE = '#F06710',
  RED = '#DE1A22',
  GREEN = '#13A813',
  PURPLE = '#B18FE8',
  WHITE = '#FFFFFF',
  GRAY = '#94A3B8',
}

export const sensorColorFromSensorStatus: Record<SensorStatusEnum, SensorColor> = {
  DEGRADED: SensorColor.ORANGE,
  DESYNCHRONIZED: SensorColor.ORANGE,
  EFFRACTION: SensorColor.RED,
  HEALTHY: SensorColor.GREEN,
  ROUND: SensorColor.GREEN,
  TRACKING: SensorColor.GREEN,
  HT_DETECTION: SensorColor.GREEN,
  NODATA: SensorColor.PURPLE,
  SLEEP: SensorColor.GRAY,
  WAITING: SensorColor.PURPLE,
  DISCONNECTED: SensorColor.PURPLE,
} as const;

export enum CriticalityCategory {
  DEGRADED = 'DEGRADED',
  MAINTENANCE = 'MAINTENANCE',
  SLEEP_INACTIVE = 'SLEEP_INACTIVE',
  OK = 'OK',
}

export const criticalityOrder = [
  CriticalityCategory.DEGRADED,
  CriticalityCategory.MAINTENANCE,
  CriticalityCategory.SLEEP_INACTIVE,
  CriticalityCategory.OK,
] as const;

export const sensorCategoryFromSensorStatus: Record<SensorStatusEnum, CriticalityCategory> = {
  DEGRADED: CriticalityCategory.DEGRADED,
  DESYNCHRONIZED: CriticalityCategory.DEGRADED,
  EFFRACTION: CriticalityCategory.OK,
  HEALTHY: CriticalityCategory.OK,
  ROUND: CriticalityCategory.OK,
  TRACKING: CriticalityCategory.OK,
  HT_DETECTION: CriticalityCategory.OK,
  NODATA: CriticalityCategory.DEGRADED,
  SLEEP: CriticalityCategory.SLEEP_INACTIVE,
  WAITING: CriticalityCategory.DEGRADED,
  DISCONNECTED: CriticalityCategory.DEGRADED,
} as const;

export enum SensorStatusCategoryEnum {
  UNREACHABLE = 'UNREACHABLE',
  TECHNICAL_DEFECT = 'TECHNICAL_DEFECT',
  MAINTENANCE = 'MAINTENANCE',
  SLEEP = 'SLEEP',
  INACTIVE = 'INACTIVE',
  OK = 'OK',
}

export const statusCategoryFromSensorStatus: Record<SensorStatusEnum, SensorStatusCategoryEnum> = {
  DEGRADED: SensorStatusCategoryEnum.TECHNICAL_DEFECT,
  DESYNCHRONIZED: SensorStatusCategoryEnum.TECHNICAL_DEFECT,
  EFFRACTION: SensorStatusCategoryEnum.OK,
  HEALTHY: SensorStatusCategoryEnum.OK,
  ROUND: SensorStatusCategoryEnum.OK,
  TRACKING: SensorStatusCategoryEnum.OK,
  HT_DETECTION: SensorStatusCategoryEnum.OK,
  NODATA: SensorStatusCategoryEnum.UNREACHABLE,
  SLEEP: SensorStatusCategoryEnum.SLEEP,
  WAITING: SensorStatusCategoryEnum.UNREACHABLE,
  DISCONNECTED: SensorStatusCategoryEnum.UNREACHABLE,
} as const;

export const statusCategoryColor: Record<SensorStatusCategoryEnum, SensorColor> = {
  [SensorStatusCategoryEnum.INACTIVE]: SensorColor.WHITE,
  [SensorStatusCategoryEnum.MAINTENANCE]: SensorColor.ORANGE,
  [SensorStatusCategoryEnum.OK]: SensorColor.GREEN,
  [SensorStatusCategoryEnum.SLEEP]: SensorColor.GRAY,
  [SensorStatusCategoryEnum.TECHNICAL_DEFECT]: SensorColor.ORANGE,
  [SensorStatusCategoryEnum.UNREACHABLE]: SensorColor.PURPLE,
} as const;

export enum LadSensorCategoryEnum {
  AD = 'AD',
  AER = 'AER',
  AVI = 'AVI',
  CAM = 'CAM',
  GCS = 'GCS',
  RF = 'RF',
  RAD = 'RAD',
  RID = 'RID',
}

export enum PerimSensorCategoryEnum {
  API = 'API',
  BT = 'BT',
  TRO = 'TRO',
}

export const sensorCategoryFromSensorType: Record<
  SensorTypeEnum | SubSensorTypeEnum,
  LadSensorCategoryEnum | PerimSensorCategoryEnum
> = {
  ADSB: LadSensorCategoryEnum.AD,
  AEROSCOPE: LadSensorCategoryEnum.AER,
  ARDRONIS: LadSensorCategoryEnum.RF,
  AUTOMATON: PerimSensorCategoryEnum.API,
  AVISO: LadSensorCategoryEnum.AVI,
  DRONE_SHIELD: LadSensorCategoryEnum.RF,
  GROUND_STATION: LadSensorCategoryEnum.GCS,
  HOLODRONE: LadSensorCategoryEnum.RID,
  HOLOPTICS: LadSensorCategoryEnum.CAM,
  ONVIF: LadSensorCategoryEnum.CAM,
  SKY_EYE: LadSensorCategoryEnum.RF,
  SPEXER: LadSensorCategoryEnum.RAD,
  XENTA: LadSensorCategoryEnum.RAD,
  ZMER: LadSensorCategoryEnum.CAM,
  SENID: LadSensorCategoryEnum.RID,
  ARDRONIS_ANTENNA: LadSensorCategoryEnum.RF,
  AUTOMATON_CABINET: PerimSensorCategoryEnum.BT,
  AUTOMATON_SEGMENT: PerimSensorCategoryEnum.TRO,
  SKY_EYE_ANTENNA: LadSensorCategoryEnum.RF,
  SPEXER_RADAR: LadSensorCategoryEnum.RAD,
  SENID_ANTENNA: LadSensorCategoryEnum.RID,
} as const;

export enum TypeEnum {
  LAD = 'LAD',
  PERIM = 'PERIM',
}

export const sensorCategoryType: Record<SensorTypeEnum | SubSensorTypeEnum, TypeEnum> = {
  ADSB: TypeEnum.LAD,
  AEROSCOPE: TypeEnum.LAD,
  ARDRONIS: TypeEnum.LAD,
  AUTOMATON: TypeEnum.PERIM,
  AVISO: TypeEnum.LAD,
  DRONE_SHIELD: TypeEnum.LAD,
  GROUND_STATION: TypeEnum.LAD,
  HOLODRONE: TypeEnum.LAD,
  HOLOPTICS: TypeEnum.LAD,
  ONVIF: TypeEnum.LAD,
  SKY_EYE: TypeEnum.LAD,
  SPEXER: TypeEnum.LAD,
  XENTA: TypeEnum.LAD,
  ZMER: TypeEnum.LAD,
  SENID: TypeEnum.LAD,
  ARDRONIS_ANTENNA: TypeEnum.LAD,
  AUTOMATON_CABINET: TypeEnum.PERIM,
  AUTOMATON_SEGMENT: TypeEnum.PERIM,
  SKY_EYE_ANTENNA: TypeEnum.LAD,
  SPEXER_RADAR: TypeEnum.LAD,
  SENID_ANTENNA: TypeEnum.LAD,
} as const;

export const sensorFamilyType: Record<SensorTypeEnum | SubSensorTypeEnum, SensorFamilyEnum> = {
  ADSB: SensorFamilyEnum.COLLABORATIVE,
  AEROSCOPE: SensorFamilyEnum.COLLABORATIVE,
  ARDRONIS: SensorFamilyEnum.RF,
  AUTOMATON: SensorFamilyEnum.AUTOMATON,
  AVISO: SensorFamilyEnum.COLLABORATIVE,
  DRONE_SHIELD: SensorFamilyEnum.RF,
  GROUND_STATION: SensorFamilyEnum.COLLABORATIVE,
  HOLODRONE: SensorFamilyEnum.COLLABORATIVE,
  HOLOPTICS: SensorFamilyEnum.CAMERA,
  ONVIF: SensorFamilyEnum.CAMERA,
  SKY_EYE: SensorFamilyEnum.RF,
  SPEXER: SensorFamilyEnum.RADAR,
  XENTA: SensorFamilyEnum.RADAR,
  ZMER: SensorFamilyEnum.CAMERA,
  SENID: SensorFamilyEnum.COLLABORATIVE,
  ARDRONIS_ANTENNA: SensorFamilyEnum.RF,
  AUTOMATON_CABINET: SensorFamilyEnum.AUTOMATON,
  AUTOMATON_SEGMENT: SensorFamilyEnum.AUTOMATON,
  SKY_EYE_ANTENNA: SensorFamilyEnum.RF,
  SPEXER_RADAR: SensorFamilyEnum.RADAR,
  SENID_ANTENNA: SensorFamilyEnum.COLLABORATIVE,
} as const;
