import { ReactComponent as BeaconIcon } from '@assets/icons/24x24/ic-beacon-24.svg';
import { ReactComponent as WorkIcon } from '@assets/icons/24x24/ic-works-24.svg';
import { ReactComponent as PhoneIcon } from '@assets/icons/40x40/ic-phoneNegative-40.svg';
import { HStack, VStack } from '@chakra-ui/layout';
import { Icon, TabPanel, Text } from '@chakra-ui/react';
import CustomSwitch from '@components/common/inputs/CustomSwitch';
import WebRelayMaintenance from '@components/map/infos/sensors/maintenance/WebRelayMaintenance';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { hasSensorRoleFromActiveProfile } from '@redux/authent/authent.selectors';
import { selectAllMonitoringEquipmentsBySensorReference } from '@redux/situation/monitoring.selectors';
import { useUpdateSensorActivationMutation, useUpdateSensorMaintenanceMutation } from '@services/c2/c2.api';
import { sensorFamilyType } from '@utils/sensors/configuration.constants';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { FormattedMessage, useIntl } from 'react-intl';

import { ActionEnum } from '@/types/commons/commons.types';
import { MapIdEnum, SensorUniqueCodes } from '@/types/map.types';
import {
  EquipmentTypeEnum,
  SensorConfiguration,
  SensorTypeEnum,
  SubSensorConfiguration,
  SubSensorTypeEnum,
} from '@/types/sensor/configuration.types';
import { SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

import SpecificMaintenance from './SpecificMaintenance';

type MaintenanceProps = {
  parentConfiguration: SensorConfiguration | null;
  sensorConfiguration: SensorConfiguration | SubSensorConfiguration;
  sensorStatus: SensorStatus | SubSensorStatus | null;
  sensorUniqueCodes: SensorUniqueCodes;
  type: SensorTypeEnum | SubSensorTypeEnum;
};

export default function Maintenance({
  parentConfiguration,
  sensorConfiguration,
  sensorStatus,
  sensorUniqueCodes,
  type,
}: Readonly<MaintenanceProps>) {
  const { formatMessage } = useIntl();

  const { mapId } = useMapContext();

  const [patchActivation] = useUpdateSensorActivationMutation();
  const [patchMaintenance] = useUpdateSensorMaintenanceMutation();

  const sensorWebRelays = useSelectorWithReplayMode(selectAllMonitoringEquipmentsBySensorReference, {
    sensorAppCode: sensorConfiguration.appCode,
    sensorCode: sensorConfiguration.code,
    equipmentType: EquipmentTypeEnum.WEB_RELAY,
  });

  const isMaintenanceInherited = parentConfiguration ? parentConfiguration.maintenance : false;
  const isSensorMaintenance = isMaintenanceInherited || sensorConfiguration.maintenance;

  const isSensorActive = parentConfiguration
    ? parentConfiguration.active && sensorConfiguration.active
    : sensorConfiguration.active;

  const isSubSensor = 'parentUniqueCode' in sensorUniqueCodes && !!parentConfiguration;

  const sensorAction = { action: ActionEnum.MAINTAIN, sensorFamily: sensorFamilyType[type] };
  const canDoSensorMaintenance = useAppSelector((state) => hasSensorRoleFromActiveProfile(state, sensorAction));

  if (!canDoSensorMaintenance) {
    return null;
  }

  const createActivationToast = (success: boolean) => {
    createToast(
      formatMessage(
        {
          id: isSensorActive ? 'contextmenu.actions.disconnectToast' : 'contextmenu.actions.connectToast',
        },
        {
          success,
          subSensorType: formatMessage(
            { id: `contextmenu.actions.subSensorType` },
            {
              subSensorType: type,
            },
          ),
          name: sensorConfiguration.name,
        },
      ),
      success ? ToastStatusEnum.SUCCESS : ToastStatusEnum.ERROR,
    );
  };

  const toggleActive = () => {
    let params;
    if (isSubSensor) {
      params = {
        id: parentConfiguration.id,
        site: parentConfiguration.site,
        appCode: parentConfiguration.appCode,
        active: !isSensorActive,
        type: (sensorConfiguration as SubSensorConfiguration).type,
        codes: [sensorConfiguration.code],
      };
    } else {
      params = {
        id: sensorConfiguration.id,
        site: (sensorConfiguration as SensorConfiguration).site,
        appCode: sensorConfiguration.appCode,
        active: !isSensorActive,
      };
    }

    patchActivation(params)
      .unwrap()
      .then(() => createActivationToast(true))
      .catch(() => createActivationToast(false));
  };

  const createMaintenanceToast = (success: boolean) => {
    createToast(
      formatMessage(
        {
          id: isSensorMaintenance
            ? 'contextmenu.actions.sensorMaintenanceOffToast'
            : 'contextmenu.actions.sensorMaintenanceOnToast',
        },
        {
          success: success,
          subSensorType: formatMessage(
            { id: `contextmenu.actions.subSensorType` },
            {
              subSensorType: type,
            },
          ),
          name: sensorConfiguration.name,
        },
      ),
      success ? ToastStatusEnum.SUCCESS : ToastStatusEnum.ERROR,
    );
  };

  const toggleMaintenance = () => {
    let params;
    if (isSubSensor) {
      params = {
        id: parentConfiguration.id,
        site: parentConfiguration.site,
        appCode: parentConfiguration.appCode,
        maintenance: !isSensorMaintenance,
        type: (sensorConfiguration as SubSensorConfiguration).type,
        codes: [sensorConfiguration.code],
      };
    } else {
      params = {
        id: sensorConfiguration.id,
        site: (sensorConfiguration as SensorConfiguration).site,
        appCode: sensorConfiguration.appCode,
        maintenance: !isSensorMaintenance,
      };
    }

    patchMaintenance(params)
      .unwrap()
      .then(() => createMaintenanceToast(true))
      .catch(() => createMaintenanceToast(false));
  };

  return (
    <TabPanel>
      <VStack padding={2} gap={2} backgroundColor="neutral.900" width="100%" userSelect="none">
        <SpecificMaintenance
          sensorStatus={sensorStatus}
          sensorUniqueCodes={sensorUniqueCodes}
          sensorConfiguration={sensorConfiguration}
          replayMode={mapId === MapIdEnum.REPLAY}
        />
        <WebRelayMaintenance monitoringWebRelays={sensorWebRelays} />
        <VStack padding={4} gap={3} backgroundColor="neutral.800" alignItems="start" width="100%">
          <HStack gap={2}>
            <CustomSwitch isChecked={isSensorActive} onClick={toggleActive} />
            <Icon as={BeaconIcon} width="24px" height="24px" color="neutral.white" />
            <Text size="md">
              <FormattedMessage
                id="sensors.maintenance.connectToggle"
                values={{
                  connected: isSensorActive,
                }}
              />
            </Text>
          </HStack>
          <HStack gap={2}>
            <CustomSwitch isChecked={isSensorMaintenance} onClick={toggleMaintenance} />
            <Icon as={WorkIcon} width="24px" height="24px" color="neutral.white" />
            <Text size="md">
              <FormattedMessage
                id="sensors.maintenance.maintenanceToggle"
                values={{
                  maintenance: isSensorMaintenance,
                }}
              />
            </Text>
          </HStack>
        </VStack>
        <HStack backgroundColor="alertHi.900" paddingX={3} paddingY={2} width="100%">
          <Text whiteSpace="pre-line" fontSize="16px" userSelect="none">
            <FormattedMessage id="sensors.emergency" />
          </Text>
          <Text variant="space" width="100%" textAlign="center" fontSize="24px">
            <FormattedMessage id="global.adminNumber" />
          </Text>
          <Icon as={PhoneIcon} color="alertHi.500" width="40px" height="40px" />
        </HStack>
      </VStack>
    </TabPanel>
  );
}
