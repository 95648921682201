import { Box } from '@chakra-ui/react';
import { CSSProperties, memo, Ref } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListOnScrollProps, VariableSizeList } from 'react-window';

import LogBookMessage from './LogBookMessage';

type Props = {
  logs: { code: string; height: number }[];
  listHeight: number;
  listRef: Ref<VariableSizeList>;
  handleScroll: (props: ListOnScrollProps) => void;
};

function LogBookList({ logs, listHeight, listRef, handleScroll }: Readonly<Props>) {
  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const { code, height } = logs[index];
    return <LogBookMessage style={style} code={code} height={height} key={code} />;
  };

  return (
    <AutoSizer style={{ width: '100%', height: '100%', position: 'relative' }} disableWidth>
      {({ height }) => {
        const isOverflowing = listHeight > height;
        return (
          <>
            <VariableSizeList
              width="100%"
              ref={listRef}
              onScroll={handleScroll}
              itemKey={(index) => logs[index].code}
              height={isOverflowing ? height : listHeight}
              itemCount={logs.length}
              style={{ flexShrink: 0 }}
              itemSize={(index) => logs[index].height}
              className="cockpit-scrollbar"
            >
              {Row}
            </VariableSizeList>
            <Box
              position="absolute"
              backgroundColor="neutral.800-op70"
              top={listHeight}
              marginTop={0.25}
              height={height - listHeight - 2}
              width="100%"
            />
          </>
        );
      }}
    </AutoSizer>
  );
}

export default memo(LogBookList);
