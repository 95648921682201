import {
  groupsAdapter,
  profilesAdapter,
  rolesAdapter,
  sitesAdapter,
  usersAdapter,
} from '@redux/authent/authent.adapters';
import { createSelector, EntityId } from '@reduxjs/toolkit';
import { isNotTechnicalProfile } from '@utils/authent/profiles.utils';
import { selectParameter, stringPropertyComparator } from '@utils/common.utils';
import { TypeEnum } from '@utils/sensors/configuration.constants';
import { denormalize } from 'normalizr';

import { Group, GroupLight, GroupWithUsers, Profile } from '@/types/authent/groups.types';
import { NormalizedUser, User } from '@/types/authent/login.types';
import { RoleEnum } from '@/types/authent/roles.types';
import { ActionEnum } from '@/types/commons/commons.types';
import { SensorAction, SensorFamilyEnum } from '@/types/sensor/sensor.types';

import { RootState } from '../store';
import { AuthentState } from './authent.reducer';
import { groupSchema, profileSchema, userSchema } from './authent.schema';

function selectAuthentState(state: RootState): AuthentState {
  return state.authent;
}

export const selectToken = createSelector(selectAuthentState, (state: AuthentState): string | null => state.token);

export const selectRenewalToken = createSelector(
  selectAuthentState,
  (state: AuthentState): string | null => state.renewalToken,
);

export const selectSessionUuid = createSelector(
  selectAuthentState,
  (state: AuthentState): string | null => state.sessionUuid,
);

export const selectHasBeenInitialized = createSelector(
  selectAuthentState,
  (state: AuthentState): boolean => state.hasBeenInitialized,
);

export const selectHasSelectedGroup = createSelector(
  selectAuthentState,
  (state: AuthentState): boolean => state.hasSelectedGroup,
);

export const selectIsChangingShift = createSelector(
  selectAuthentState,
  (state: AuthentState): boolean => state.isChangingShift,
);

export const selectActiveUser = createSelector(selectAuthentState, (state: AuthentState): User => state.activeUser);

export const selectActiveSite = createSelector(selectActiveUser, (state: User) => state.activeSite);

export const selectUserSites = createSelector(selectActiveUser, (state: User) => state.sites);

export const selectUserGroups = createSelector(selectActiveUser, (state: User): GroupLight[] => state.groups);

export const selectActiveGroup = createSelector(selectActiveUser, (state: User): Group | null => state.activeGroup);

export const selectActiveProfile = createSelector(
  selectActiveGroup,
  (state: Group | null): Profile | null => state?.profile ?? null,
);

export const selectRolesFromActiveProfile = createSelector(selectActiveProfile, (profile: Profile | null) => {
  if (profile === null) {
    return null;
  }
  return new Set(profile.roles.map((role) => role.roleName));
});

export const selectSensorRolesFromActiveProfile = createSelector(
  selectActiveProfile,
  (profile: Profile | null): Set<SensorAction> | null => {
    if (profile === null) {
      return null;
    }
    return new Set(
      profile.roles
        .filter((role) => role.action && role.sensorFamily)
        .map(
          (role) =>
            ({
              action: role.action,
              sensorFamily: role.sensorFamily,
            }) as SensorAction,
        ),
    );
  },
);

export const hasSensorRoleFromActiveProfile = createSelector(
  [selectSensorRolesFromActiveProfile, selectParameter<SensorAction>],
  (sensorActionSet: Set<SensorAction> | null, sensorAction: SensorAction): boolean => {
    if (sensorActionSet === null) {
      return false;
    }
    return Array.from(sensorActionSet).some(
      (s) => s.action === sensorAction.action && s.sensorFamily === sensorAction.sensorFamily,
    );
  },
);

export const hasSensorLADorPerimAccessFromActiveProfile = createSelector(
  [selectSensorRolesFromActiveProfile, selectParameter<TypeEnum>],
  (sensorActionSet: Set<SensorAction> | null, type: TypeEnum): boolean => {
    if (sensorActionSet === null) {
      return false;
    }

    return Array.from(sensorActionSet)
      .filter(
        (s) =>
          (type === TypeEnum.LAD && s.sensorFamily !== SensorFamilyEnum.AUTOMATON) ||
          (type !== TypeEnum.LAD && s.sensorFamily === SensorFamilyEnum.AUTOMATON),
      )
      .some((s) => s.action === ActionEnum.ACCESS);
  },
);

export const hasAnyRoles = createSelector(
  [selectRolesFromActiveProfile, selectParameter<RoleEnum[]>],
  (roleSet: Set<RoleEnum> | null, roles): boolean => {
    if (roleSet === null) {
      return false;
    }
    return roles.some((role) => roleSet.has(role));
  },
);

export const hasRoles = createSelector(
  [selectRolesFromActiveProfile, selectParameter<RoleEnum[]>],
  (roleSet: Set<RoleEnum> | null, roles: RoleEnum[]) => {
    if (roleSet === null) {
      return null;
    }
    return new Set(roles.filter((role) => roleSet.has(role)));
  },
);

export const hasRole = createSelector(
  [selectRolesFromActiveProfile, selectParameter<RoleEnum>],
  (roleSet: Set<RoleEnum> | null, role: RoleEnum) => {
    if (roleSet === null) {
      return false;
    }
    return roleSet.has(role);
  },
);

export const hasActionRoleOnSensors = createSelector(
  [selectSensorRolesFromActiveProfile, selectParameter<ActionEnum>],
  (sensorActionSet: Set<SensorAction> | null, action: ActionEnum): boolean => {
    if (!sensorActionSet) {
      return false;
    }
    return Array.from(sensorActionSet).some((sensorAction) => sensorAction.action === action);
  },
);

const { selectAll: selectAllRoles, selectEntities: selectRoleEntities } = rolesAdapter.getSelectors(
  (state: RootState) => state.authent.roles,
);

export const RoleSelectors = {
  selectAllRoles,
};

const { selectAll: selectAllProfilesWithTech, selectEntities: selectProfileEntities } = profilesAdapter.getSelectors(
  (state: RootState) => state.authent.profiles,
);

const { selectEntities: selectSiteEntities } = sitesAdapter.getSelectors((state: RootState) => state.authent.sites);

const { selectAll: selectAllUsers, selectEntities: selectUserEntities } = usersAdapter.getSelectors(
  (state: RootState) => state.authent.users,
);

const selectUserById = usersAdapter.getSelectors((state: RootState) => state.authent.users).selectById as (
  state: RootState,
  id: EntityId,
) => NormalizedUser | undefined;

const { selectAll: selectAllGroups, selectEntities: selectGroupEntities } = groupsAdapter.getSelectors(
  (state: RootState) => state.authent.groups,
);

export const GroupSelectors = {
  selectAllGroups,
};

export const selectGlobalScreenConfigurationCode = createSelector(
  selectActiveGroup,
  (state: Group | null): string => state?.globalScreenConfig ?? '',
);

const selectEntities = createSelector(
  [selectUserEntities, selectProfileEntities, selectRoleEntities, selectSiteEntities, selectGroupEntities],
  (users, profiles, roles, sites, groups) => ({
    users,
    profiles,
    roles,
    sites,
    groups,
  }),
);

export const selectDenormalizedUsers = createSelector([selectEntities, selectAllUsers], (entities, users): User[] =>
  users.map((user) => denormalize(user.id, userSchema, entities)),
);

export const selectDenormalizedUser = createSelector(
  [selectEntities, selectUserById],
  (entities, user): User | null => denormalize(user?.id, userSchema, entities) ?? null,
);

export const selectDenormalizedProfiles = createSelector(
  [selectEntities, selectAllProfilesWithTech],
  (entities, profiles): Profile[] =>
    profiles
      .map((profile) => denormalize(profile.id, profileSchema, entities))
      .filter(isNotTechnicalProfile)
      .toSorted(stringPropertyComparator('name')),
);

export const selectDenormalizedProfileById = createSelector(
  [selectDenormalizedProfiles, selectParameter<number>],
  (profiles: Profile[], id: number) => {
    return profiles.find((profile) => profile.id === id);
  },
);

export const selectDenormalizedGroups = createSelector(
  [selectEntities, selectAllGroups],
  (entities, groups): GroupWithUsers[] =>
    groups
      .map((group) => denormalize(group.code, groupSchema, entities))
      .filter((group) => isNotTechnicalProfile(group.profile)),
);
