import { Box, Text, VStack } from '@chakra-ui/layout';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { calculateMeasure } from '@utils/map/measure.utils';
import { convertDistanceUnitFromMeter, DistanceUnits } from '@utils/unit.utils';
import { formatCoordinatesToString } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { FormattedMessage } from 'react-intl';

import { Coordinates } from '@/types/commons/commons.types';

type Props = {
  tooltipPosition: Coordinates;
};

export default function AzimuthAndDistanceTooltip({ tooltipPosition }: Readonly<Props>) {
  const { mousePosition, measurePoints, isClickEnabled } = useMapContext();
  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);
  const dmsCoordinates = new DmsCoordinates(tooltipPosition.latitude, tooltipPosition.longitude);
  const [lat, long] = formatCoordinatesToString(dmsCoordinates, userSettingsUnit).split(',');

  if (!mousePosition) {
    return;
  }

  const [distance, azimuth] = calculateMeasure(mousePosition, measurePoints, isClickEnabled);
  const isShortDistance = measurePoints.length < 2 || (!isClickEnabled && measurePoints.length === 2);
  const segmentName = ` ${
    isClickEnabled
      ? `${measurePoints.length}-${measurePoints.length + 1}`
      : `${measurePoints.length - 1}-${measurePoints.length}`
  }`;

  return (
    <VStack spacing={2} alignItems="flex-start">
      {measurePoints.length > 0 && (
        <>
          <Box>
            <Text fontSize="16px">
              <FormattedMessage
                id="controls.toolMenu.measure.azimuthAndDistance.distance"
                values={{ isShortDistance }}
              />
            </Text>
            <Text fontSize="16px" as="b">
              {distance}m {convertDistanceUnitFromMeter(DistanceUnits.NAUTICAL_MILES, distance)}NM
            </Text>
          </Box>
          <Box>
            <Text fontSize="16px">
              <FormattedMessage id="controls.toolMenu.measure.azimuthAndDistance.azimuth" />
              {isShortDistance ? '' : segmentName}
            </Text>
            <Text fontSize="16px" as="b">
              {azimuth.toString().replace('.', '°')}
            </Text>
          </Box>
        </>
      )}
      <VStack alignItems="flex-start">
        <Text fontSize="16px">
          <FormattedMessage id="controls.toolMenu.measure.azimuthAndDistance.point" />{' '}
          {isClickEnabled ? measurePoints.length + 1 : measurePoints.length}
        </Text>
        <Text fontSize="16px" as="b">
          LAT {lat}
        </Text>
        <Text fontSize="16px" as="b">
          LONG {long}
        </Text>
      </VStack>
    </VStack>
  );
}
