import { CameraType, SensorTypeEnum } from '@/types/sensor/configuration.types';

export const cameraRange: Record<CameraType, number> = {
  [SensorTypeEnum.HOLOPTICS]: 7000,
  [SensorTypeEnum.ZMER]: 3000,
  [SensorTypeEnum.ONVIF]: 3000,
};

export enum HolopticsSubCameraEnum {
  PC,
  GC,
  SWIR,
  MWIR,
}

enum ZmerSubCameraEnum {
  DAY,
  IR,
}

export type SubCameraType = HolopticsSubCameraEnum | ZmerSubCameraEnum;

type SubCameraFeatureType = boolean | SubCameraType[];

export type CameraAvailableFeatures = {
  zoom?: boolean;
  focus?: SubCameraFeatureType;
  roundOn?: boolean;
  autoFocus?: SubCameraFeatureType;
  autoFocusPerm?: boolean;
  iso?: SubCameraFeatureType;
  polarity?: SubCameraFeatureType;
  deIcing?: SubCameraFeatureType;
  whiteBalance?: SubCameraFeatureType;
  hybridFocus?: SubCameraFeatureType;
  zoomSync?: boolean;
};

export const subCamera: Record<CameraType, { type: SubCameraType; fluxId: number }[]> = {
  [SensorTypeEnum.HOLOPTICS]: [
    { type: HolopticsSubCameraEnum.PC, fluxId: 0 },
    { type: HolopticsSubCameraEnum.GC, fluxId: 1 },
    { type: HolopticsSubCameraEnum.SWIR, fluxId: 2 },
    { type: HolopticsSubCameraEnum.MWIR, fluxId: 3 },
  ],
  [SensorTypeEnum.ZMER]: [
    { type: ZmerSubCameraEnum.DAY, fluxId: 0 },
    { type: ZmerSubCameraEnum.IR, fluxId: 1 },
  ],
  [SensorTypeEnum.ONVIF]: [],
};

export const cameraAvailableFeatures: Record<CameraType, CameraAvailableFeatures> = {
  [SensorTypeEnum.HOLOPTICS]: {
    zoom: true,
    focus: [HolopticsSubCameraEnum.PC, HolopticsSubCameraEnum.GC],
    roundOn: true,
    autoFocus: true,
    autoFocusPerm: true,
    iso: [HolopticsSubCameraEnum.PC, HolopticsSubCameraEnum.GC],
    polarity: [HolopticsSubCameraEnum.SWIR, HolopticsSubCameraEnum.MWIR],
    deIcing: [HolopticsSubCameraEnum.PC, HolopticsSubCameraEnum.GC, HolopticsSubCameraEnum.SWIR],
    whiteBalance: [HolopticsSubCameraEnum.PC, HolopticsSubCameraEnum.GC],
    hybridFocus: [HolopticsSubCameraEnum.SWIR, HolopticsSubCameraEnum.MWIR],
    zoomSync: true,
  },
  [SensorTypeEnum.ZMER]: {
    zoom: true,
    focus: true,
    roundOn: true,
    autoFocus: true,
    autoFocusPerm: true,
    zoomSync: true,
  },
  [SensorTypeEnum.ONVIF]: {},
};
