import { ReactComponent as CriticalBorderDecoration } from '@assets/components/toast/critical-alert-border-toast-decoration.svg';
import { ReactComponent as ConfirmedIcon24 } from '@assets/icons/24x24/ic-confirmed-bold-24.svg';
import { Icon } from '@chakra-ui/icon';
import { Center, HStack, VStack } from '@chakra-ui/layout';
import { Spinner, Text } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectSituationTime } from '@redux/situation/situation.selectors';
import { useAcknowledgeNotificationMutation } from '@services/data/notification.api';
import {
  getAlertColor,
  isPerimeterEffractionAlert,
  isSegmentFailureAlert,
  isSensorFailureAlert,
  isSpaceViolationAlert,
} from '@utils/data/notification.utils';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Alert,
  AlertLevelEnum,
  PerimeterEffractionAlert,
  SegmentFailureAlert,
  SensorAlert,
  SpaceViolationAlert,
} from '@/types/data/data.types';

import PerimeterEffractionAlertToast from './PerimeterEffractionAlertToast';
import SegmentFailureAlertToast from './SegmentFailureAlertToast';
import SensorAlertToast from './SensorAlertToast';
import SpaceViolationAlertToast from './SpaceViolationAlertToast';

type Props = {
  alert: Alert;
  onClose: () => void;
};

function AlertToast({ alert, onClose }: Readonly<Props>) {
  const currentSituationTime = useAppSelector(selectSituationTime);
  const [acknowledge] = useAcknowledgeNotificationMutation();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading && alert.ack) {
      setIsLoading(false);
      onClose();
    }
  }, [alert.ack, isLoading, onClose]);

  function onClick() {
    if (!isLoading) {
      setIsLoading(true);
      acknowledge(alert).finally(() => {
        setIsLoading(false);
        onClose();
      });
    }
  }

  const currentDate = new Date(currentSituationTime ?? Date.now());

  function displayAlert() {
    if (isSpaceViolationAlert(alert)) {
      return <SpaceViolationAlertToast alert={alert as SpaceViolationAlert} currentDate={currentDate} />;
    }
    if (isSensorFailureAlert(alert)) {
      return <SensorAlertToast alert={alert as SensorAlert} currentDate={currentDate} />;
    }
    if (isPerimeterEffractionAlert(alert)) {
      return (
        <PerimeterEffractionAlertToast
          alert={alert as PerimeterEffractionAlert}
          currentDate={currentDate}
          onClose={onClose}
        />
      );
    }
    if (isSegmentFailureAlert(alert)) {
      return <SegmentFailureAlertToast alert={alert as SegmentFailureAlert} currentDate={currentDate} />;
    }
  }

  return (
    <HStack
      gap={0}
      backgroundColor="neutral.100"
      borderLeft={alert.level === AlertLevelEnum.CRITICAL ? 'none' : '8px solid'}
      borderColor={getAlertColor(alert.level)}
      width="360px"
      position="relative"
      userSelect="none"
      flexGrow={0}
      flexShrink={0}
      overflow="hidden"
    >
      {alert.level === AlertLevelEnum.CRITICAL && (
        <Icon as={CriticalBorderDecoration} position="absolute" top={0} height="371px" width="16px" flexShrink={0} />
      )}
      <VStack gap={0} height="100%" width="100%">
        {displayAlert()}
        <Center
          width="100%"
          paddingBottom={2}
          paddingLeft={alert.level === AlertLevelEnum.CRITICAL ? 5 : 4}
          paddingRight={3}
        >
          <HStack
            paddingX={3}
            paddingY="10px"
            gap={1.5}
            width="100%"
            height="52px"
            backgroundColor="neutral.800"
            border="2px"
            borderColor="neutral.200"
            boxShadow="inset 0 0 0 2px var(--chakra-colors-neutral-900)"
            cursor="pointer"
            onClick={onClick}
          >
            {isLoading ? (
              <Spinner margin="auto" color="neutral.white" />
            ) : (
              <>
                <Icon as={ConfirmedIcon24} width="24px" height="24px" color="neutral.300" marginLeft={2} />
                <Text fontSize="16px" variant="space">
                  <FormattedMessage id="notification.acknowledge" />
                </Text>
              </>
            )}
          </HStack>
        </Center>
      </VStack>
    </HStack>
  );
}

export default AlertToast;
