import { ReactComponent as EventIcon } from '@assets/icons/24x24/ic-warning-24.svg';
import { ReactComponent as EnemyClassificationIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-alertHigh.svg';
import { ReactComponent as WatchClassificationIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-alertLow.svg';
import { ReactComponent as SuspectClassificationIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-alertMid.svg';
import { ReactComponent as FriendClassificationIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-alertZero.svg';
import { ReactComponent as UnknownClassificationIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-Disabled.svg';
import { ReactComponent as UnsureIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-notSure.svg';
import { ReactComponent as SureIcon } from '@assets/icons/24x24/target-ic-statusColor/ic-statusColor-24-sure.svg';
import { ReactComponent as BuildingSignIcon } from '@assets/icons/24x24/target-icSign/icSign-beacon-24.svg';
import { ReactComponent as BirdSignIcon } from '@assets/icons/24x24/target-icSign/icSign-birds-24.svg';
import { ReactComponent as ShipSignIcon } from '@assets/icons/24x24/target-icSign/icSign-boat-24.svg';
import { ReactComponent as VehicleSignIcon } from '@assets/icons/24x24/target-icSign/icSign-car-24.svg';
import { ReactComponent as DroneSignIcon } from '@assets/icons/24x24/target-icSign/icSign-drone-24.svg';
import { ReactComponent as HelicopterSignIcon } from '@assets/icons/24x24/target-icSign/icSign-helico-24.svg';
import { ReactComponent as PersonSignIcon } from '@assets/icons/24x24/target-icSign/icSign-human-24.svg';
import { ReactComponent as AircraftSignIcon } from '@assets/icons/24x24/target-icSign/icSign-plane-24.svg';
import { ReactComponent as RemoteControlSignIcon } from '@assets/icons/24x24/target-icSign/icSign-remoteControl-24.svg';
import { ReactComponent as UnknownSignIcon } from '@assets/icons/24x24/target-icSign/icSign-unknown-24.svg';

import { FilterOption, MapFilters } from '@/types/filters.types';
import { SensorFamilyEnum } from '@/types/sensor/sensor.types';

export const mapFiltersAllChecked: MapFilters = {
  displayTargetDrone: true,
  displayTargetRemote: true,
  displayTargetAircraft: true,
  displayTargetHelicopter: true,
  displayTargetBird: true,
  displayTargetVehicle: true,
  displayTargetShip: true,
  displayTargetPerson: true,
  displayTargetTestBeacon: true,
  displayTargetUnknown: true,

  displayTrajectoryDrone: true,
  displayTrajectoryRemoteControl: true,
  displayTrajectoryAircraft: true,
  displayTrajectoryHelicopter: true,
  displayTrajectoryBird: true,
  displayTrajectoryVehicle: true,
  displayTrajectoryShip: true,
  displayTrajectoryPerson: true,
  displayTrajectoryTestBeacon: true,
  displayTrajectoryUnknown: true,
  displayTrajectoryPinnedTarget: true,

  displayRfDetectionDroneUav: true,
  displayRfDetectionRemoteControl: true,
  displayRfDetectionUnknown: true,

  displayReliabilitySafe: true,
  displayReliabilityUnsafe: true,

  displayDangerLevelAuthorized: true,
  displayDangerLevelUnknown: true,
  displayDangerLevelFriend: true,
  displayDangerLevelSuspect: true,
  displayDangerLevelEnemy: true,

  displayLadSensorHolodrone: true,
  displayLadSensorSenid: true,
  displayLadSensorAeroscope: true,
  displayLadSensorAdsb: true,
  displayLadSensorArdronis: true,
  displayLadSensorSkyeye: true,
  displayLadSensorZmer: true,
  displayLadSensorHoloptics: true,
  displayLadSensorSpexer: true,
  displayLadSensorXenta: true,
  displayLadSensorAviso: true,

  displayPerimeterSensorAutomaton: true,
  displayPerimeterSensorCabinet: true,
  displayPerimeterSensorSegment: true,
  displayPerimeterSensorCamera: true,

  displaySensorInfoPosition: true,
  displaySensorInfoCoverage: true,
  displaySensorInfoPerimeterCameraFov: true,
  displaySensorInfoLadCameraFov: true,
  displaySensorInfoDisabledSensor: true,

  displayRadarZoneNoInitZone: true,
  displayRadarZoneInitMaskingZone: true,

  displayDangerZoneObservation: true,
  displayDangerZoneVigilance: true,
  displayDangerZoneCritical: true,

  displayFlightplanActive: true,
  displayFlightplanUpcoming: true,

  displayOtherInfoEvent: true,
  displayOtherInfoPoi: true,
  displayOtherInfoZoi: true,
  displayOtherInfoDetectionTargetLost: true,

  displayMapLayerAeronautics: true,
};

export const mapFiltersAllUnchecked: MapFilters = {
  displayTargetDrone: false,
  displayTargetRemote: false,
  displayTargetAircraft: false,
  displayTargetHelicopter: false,
  displayTargetBird: false,
  displayTargetVehicle: false,
  displayTargetShip: false,
  displayTargetPerson: false,
  displayTargetTestBeacon: false,
  displayTargetUnknown: false,

  displayTrajectoryDrone: false,
  displayTrajectoryRemoteControl: false,
  displayTrajectoryAircraft: false,
  displayTrajectoryHelicopter: false,
  displayTrajectoryBird: false,
  displayTrajectoryVehicle: false,
  displayTrajectoryShip: false,
  displayTrajectoryPerson: false,
  displayTrajectoryTestBeacon: false,
  displayTrajectoryUnknown: false,
  displayTrajectoryPinnedTarget: false,

  displayRfDetectionDroneUav: false,
  displayRfDetectionRemoteControl: false,
  displayRfDetectionUnknown: false,

  displayReliabilitySafe: false,
  displayReliabilityUnsafe: false,

  displayDangerLevelAuthorized: false,
  displayDangerLevelUnknown: false,
  displayDangerLevelFriend: false,
  displayDangerLevelSuspect: false,
  displayDangerLevelEnemy: false,

  displayLadSensorHolodrone: false,
  displayLadSensorSenid: false,
  displayLadSensorAeroscope: false,
  displayLadSensorAdsb: false,
  displayLadSensorArdronis: false,
  displayLadSensorSkyeye: false,
  displayLadSensorZmer: false,
  displayLadSensorHoloptics: false,
  displayLadSensorSpexer: false,
  displayLadSensorXenta: false,
  displayLadSensorAviso: false,

  displayPerimeterSensorAutomaton: false,
  displayPerimeterSensorCabinet: false,
  displayPerimeterSensorSegment: false,
  displayPerimeterSensorCamera: false,

  displaySensorInfoPosition: false,
  displaySensorInfoCoverage: false,
  displaySensorInfoPerimeterCameraFov: false,
  displaySensorInfoLadCameraFov: false,
  displaySensorInfoDisabledSensor: false,

  displayRadarZoneNoInitZone: false,
  displayRadarZoneInitMaskingZone: false,

  displayDangerZoneObservation: false,
  displayDangerZoneVigilance: false,
  displayDangerZoneCritical: false,

  displayFlightplanActive: false,
  displayFlightplanUpcoming: false,

  displayOtherInfoEvent: false,
  displayOtherInfoPoi: false,
  displayOtherInfoZoi: false,
  displayOtherInfoDetectionTargetLost: false,

  displayMapLayerAeronautics: false,
};

export const filterOptionsTargetCategory: FilterOption[] = [
  {
    key: 'displayTargetDrone',
    label: 'target.identification.DRONE',
    Image: DroneSignIcon,
  },
  {
    key: 'displayTargetRemote',
    label: 'target.identification.REMOTE_CONTROL',
    Image: RemoteControlSignIcon,
  },
  {
    key: 'displayTargetAircraft',
    label: 'target.identification.AIRCRAFT',
    Image: AircraftSignIcon,
  },
  {
    key: 'displayTargetHelicopter',
    label: 'target.identification.HELICOPTER',
    Image: HelicopterSignIcon,
  },
  {
    key: 'displayTargetBird',
    label: 'target.identification.BIRD',
    Image: BirdSignIcon,
  },
  {
    key: 'displayTargetVehicle',
    label: 'target.identification.GROUND_VEHICLE',
    Image: VehicleSignIcon,
  },
  {
    key: 'displayTargetShip',
    label: 'target.identification.SHIP',
    Image: ShipSignIcon,
  },
  {
    key: 'displayTargetPerson',
    label: 'target.identification.PERSON',
    Image: PersonSignIcon,
  },
  {
    key: 'displayTargetTestBeacon',
    label: 'target.identification.BUILDING',
    Image: BuildingSignIcon,
  },
  {
    key: 'displayTargetUnknown',
    label: 'target.identification.UNKNOWN',
    Image: UnknownSignIcon,
  },
];

export const filterOptionsTrajectoryCategory: FilterOption[] = [
  {
    key: 'displayTrajectoryDrone',
    label: 'target.identification.DRONE',
  },
  {
    key: 'displayTrajectoryRemoteControl',
    label: 'target.identification.REMOTE_CONTROL',
  },
  {
    key: 'displayTrajectoryAircraft',
    label: 'target.identification.AIRCRAFT',
  },
  {
    key: 'displayTrajectoryHelicopter',
    label: 'target.identification.HELICOPTER',
  },
  {
    key: 'displayTrajectoryBird',
    label: 'target.identification.BIRD',
  },
  {
    key: 'displayTrajectoryVehicle',
    label: 'target.identification.GROUND_VEHICLE',
  },
  {
    key: 'displayTrajectoryShip',
    label: 'target.identification.SHIP',
  },
  {
    key: 'displayTrajectoryPerson',
    label: 'target.identification.PERSON',
  },
  {
    key: 'displayTrajectoryTestBeacon',
    label: 'target.identification.BUILDING',
  },
  {
    key: 'displayTrajectoryUnknown',
    label: 'target.identification.UNKNOWN',
  },
  {
    key: 'displayTrajectoryPinnedTarget',
    label: 'filters.trajectory.pinnedTarget',
  },
];

export const filterOptionsRfDetectionCategory: FilterOption[] = [
  {
    key: 'displayRfDetectionDroneUav',
    label: 'filters.rfDetection.droneAndUav',
  },
  {
    key: 'displayRfDetectionRemoteControl',
    label: 'target.identification.REMOTE_CONTROL',
  },
  {
    key: 'displayRfDetectionUnknown',
    label: 'target.identification.UNKNOWN',
  },
];

export const filterOptionsReliabilityCategory: FilterOption[] = [
  {
    key: 'displayReliabilitySafe',
    label: 'filters.reliability.sure',
    Image: SureIcon,
  },
  {
    key: 'displayReliabilityUnsafe',
    label: 'filters.reliability.unsure',
    Image: UnsureIcon,
  },
];

export const filterOptionsDangerLevelCategory: FilterOption[] = [
  {
    key: 'displayDangerLevelAuthorized',
    label: 'target.classification.FRIEND',
    Image: FriendClassificationIcon,
  },
  {
    key: 'displayDangerLevelUnknown',
    label: 'target.classification.UNKNOWN',
    Image: UnknownClassificationIcon,
  },
  {
    key: 'displayDangerLevelFriend',
    label: 'target.classification.WATCH',
    Image: WatchClassificationIcon,
  },
  {
    key: 'displayDangerLevelSuspect',
    label: 'target.classification.SUSPECT',
    Image: SuspectClassificationIcon,
  },
  {
    key: 'displayDangerLevelEnemy',
    label: 'target.classification.ENEMY',
    Image: EnemyClassificationIcon,
  },
];

export const filterOptionsLadSensorsCategory: FilterOption[] = [
  {
    key: 'displayLadSensorHolodrone',
    label: 'filters.ladSensors.holodrone',
    sensorFamily: SensorFamilyEnum.COLLABORATIVE,
  },
  {
    key: 'displayLadSensorSenid',
    label: 'filters.ladSensors.senid',
    sensorFamily: SensorFamilyEnum.COLLABORATIVE,
  },
  {
    key: 'displayLadSensorAeroscope',
    label: 'filters.ladSensors.aeroscope',
    sensorFamily: SensorFamilyEnum.COLLABORATIVE,
  },
  {
    key: 'displayLadSensorAdsb',
    label: 'filters.ladSensors.adsb',
    sensorFamily: SensorFamilyEnum.COLLABORATIVE,
  },
  {
    key: 'displayLadSensorArdronis',
    label: 'filters.ladSensors.ardronis',
    sensorFamily: SensorFamilyEnum.RF,
  },
  {
    key: 'displayLadSensorSkyeye',
    label: 'filters.ladSensors.skyeye',
    sensorFamily: SensorFamilyEnum.RF,
  },
  {
    key: 'displayLadSensorZmer',
    label: 'filters.ladSensors.zmer',
    sensorFamily: SensorFamilyEnum.CAMERA,
  },
  {
    key: 'displayLadSensorHoloptics',
    label: 'filters.ladSensors.holoptics',
    sensorFamily: SensorFamilyEnum.CAMERA,
  },
  {
    key: 'displayLadSensorSpexer',
    label: 'filters.ladSensors.spexer',
    sensorFamily: SensorFamilyEnum.RADAR,
  },
  {
    key: 'displayLadSensorXenta',
    label: 'filters.ladSensors.xenta',
    sensorFamily: SensorFamilyEnum.RADAR,
  },
  {
    key: 'displayLadSensorAviso',
    label: 'filters.ladSensors.aviso',
    sensorFamily: SensorFamilyEnum.COLLABORATIVE,
  },
];

export const filterOptionsPerimeterSensorsCategory: FilterOption[] = [
  {
    key: 'displayPerimeterSensorAutomaton',
    label: 'filters.perimeterSensors.automaton',
  },
  {
    key: 'displayPerimeterSensorCabinet',
    label: 'filters.perimeterSensors.cabinet',
  },
  {
    key: 'displayPerimeterSensorSegment',
    label: 'filters.perimeterSensors.segment',
  },
  {
    key: 'displayPerimeterSensorCamera',
    label: 'filters.perimeterSensors.camera',
  },
];

export const filterOptionsSensorInfoCategory: FilterOption[] = [
  {
    key: 'displaySensorInfoPosition',
    label: 'filters.sensorInfo.sensorsPosition',
  },
  {
    key: 'displaySensorInfoCoverage',
    label: 'filters.sensorInfo.sensorCoverage',
  },
  {
    key: 'displaySensorInfoPerimeterCameraFov',
    label: 'filters.sensorInfo.perimeterCameraFov',
  },
  {
    key: 'displaySensorInfoLadCameraFov',
    label: 'filters.sensorInfo.ladCameraFov',
  },
  {
    key: 'displaySensorInfoDisabledSensor',
    label: 'filters.sensorInfo.disabledSensor',
  },
];

export const filterOptionsRadarZoneCategory: FilterOption[] = [
  {
    key: 'displayRadarZoneNoInitZone',
    label: 'filters.radarZone.niz',
  },
  {
    key: 'displayRadarZoneInitMaskingZone',
    label: 'filters.radarZone.imz',
  },
];

export const filterOptionsDangerZoneCategory: FilterOption[] = [
  {
    key: 'displayDangerZoneObservation',
    label: 'filters.dangerZone.observation',
  },
  {
    key: 'displayDangerZoneVigilance',
    label: 'filters.dangerZone.vigilance',
  },
  {
    key: 'displayDangerZoneCritical',
    label: 'filters.dangerZone.instantDanger',
  },
];

export const filterOptionsFlightplanCategory: FilterOption[] = [
  {
    key: 'displayFlightplanActive',
    label: 'filters.flightplan.active',
  },
  {
    key: 'displayFlightplanUpcoming',
    label: 'filters.flightplan.upcoming',
  },
];

export const filterOptionsOtherInfoCategory: FilterOption[] = [
  {
    key: 'displayOtherInfoEvent',
    label: 'filters.otherInfo.event',
    Image: EventIcon,
  },
  {
    key: 'displayOtherInfoPoi',
    label: 'filters.otherInfo.poi',
  },
  {
    key: 'displayOtherInfoZoi',
    label: 'filters.otherInfo.zoi',
  },
];

export const filterOptionsMapLayerCategory: FilterOption[] = [
  {
    key: 'displayMapLayerAeronautics',
    label: 'filters.mapLayer.aeronautics',
  },
];
