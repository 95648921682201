import { TabPanels, Tabs } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import {
  selectSensorConfigurationByUniqueCode,
  selectSensorOrSubSensorConfigurationByUniqueCodes,
  selectSensorStatusByUniqueCode,
} from '@redux/situation/monitoring.selectors';
import { Dispatch } from 'react';

import { SensorUniqueCodes } from '@/types/map.types';
import { SubSensorConfiguration } from '@/types/sensor/configuration.types';

import GlobalTab from './global/GlobalTab';
import SensorDetailHeader from './SensorDetailHeader';

type Props = {
  sensorUniqueCodes: SensorUniqueCodes;
  setSelectedSensorCode: Dispatch<SensorUniqueCodes | null>;
};

export default function SensorDetail({ sensorUniqueCodes, setSelectedSensorCode }: Readonly<Props>) {
  const sensorConfiguration = useAppSelector((state) =>
    selectSensorOrSubSensorConfigurationByUniqueCodes(state, false, sensorUniqueCodes),
  );
  const sensorStatus = useAppSelector((state) =>
    selectSensorStatusByUniqueCode(
      state,
      false,
      sensorUniqueCodes.parentUniqueCode ?? sensorUniqueCodes.sensorUniqueCode,
    ),
  );
  const parentConfiguration = useAppSelector((state) =>
    selectSensorConfigurationByUniqueCode(state, false, sensorUniqueCodes.parentUniqueCode ?? null),
  );

  if (sensorConfiguration === null) {
    setSelectedSensorCode(null);
    return null;
  }

  const sensorType = parentConfiguration ? parentConfiguration.type : sensorConfiguration.type;
  const subSensorType = parentConfiguration ? (sensorConfiguration as SubSensorConfiguration).type : null;

  return (
    <Tabs width="100%" height="100%" variant="styledTab">
      <SensorDetailHeader
        sensorConfiguration={sensorConfiguration}
        sensorStatus={sensorStatus}
        onClose={() => setSelectedSensorCode(null)}
      />
      <TabPanels height="100%" paddingTop={0.25} backgroundColor="neutral.black">
        <GlobalTab
          parentConfiguration={parentConfiguration}
          sensorConfiguration={sensorConfiguration}
          sensorStatus={sensorStatus}
          sensorUniqueCodes={sensorUniqueCodes}
          type={subSensorType ?? sensorType}
        />
      </TabPanels>
    </Tabs>
  );
}
