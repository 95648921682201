import { VStack } from '@chakra-ui/layout';
import MenuList from '@components/common/menu/MenuList';
import { useAutomatonCabinetSensorContextMenu } from '@components/map/contextMenu/sensor/useAutomatonCabinetSensorContextMenu';
import { useAutomatonSensorContextMenu } from '@components/map/contextMenu/sensor/useAutomatonSensorContextMenu';
import { useCameraSensorContextMenu } from '@components/map/contextMenu/sensor/useCameraSensorContextMenu';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { MeasureOriginEnum, useCommonContextMenuItems } from '@hooks/useCommonContextMenuItems';
import { useSelectorWithMapId } from '@hooks/useSelectorWithMapId';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { useWithDispatch } from '@hooks/useWithDispatch';
import { hasSensorRoleFromActiveProfile } from '@redux/authent/authent.selectors';
import {
  SelectedFeatureTypeEnum,
  updatePopupControlByMapId,
  updateSelectedFeatureKeyByMapId,
} from '@redux/maps/maps.reducer';
import { selectPopupControlByMapId } from '@redux/maps/maps.selectors';
import {
  selectSensorConfigurationByUniqueCode,
  selectSensorOrSubSensorConfigurationByUniqueCodes,
  selectSensorOrSubSensorStatusByUniqueCodes,
} from '@redux/situation/monitoring.selectors';
import { useUpdateSensorActivationMutation, useUpdateSensorMaintenanceMutation } from '@services/c2/c2.api';
import { sensorFamilyType } from '@utils/sensors/configuration.constants';
import { getSensorUniqueCode } from '@utils/sensors/sensors.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ActionEnum, Coordinates } from '@/types/commons/commons.types';
import {
  FeatureTypeEnum,
  PopupControlType,
  PopupDataTypeEnum,
  SensorInfoData,
  SensorLikeDisplayedOnMap,
  SensorUniqueCodes,
} from '@/types/map.types';
import { MenuItemOption, MenuItems } from '@/types/menu.types';
import {
  SensorConfiguration,
  SensorTypeEnum,
  SubSensorConfiguration,
  SubSensorTypeEnum,
} from '@/types/sensor/configuration.types';

interface OwnProps {
  clickPosition: { x: number; y: number } | null;
  sensorUniqueCodes: SensorUniqueCodes;
  type?: SensorLikeDisplayedOnMap;
  onClose: () => void;
}

function SensorContextMenu({ clickPosition, sensorUniqueCodes, type, onClose }: Readonly<OwnProps>) {
  const { mapId } = useMapContext();
  const { formatMessage } = useIntl();
  const popupControl = useSelectorWithMapId(selectPopupControlByMapId);
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const updateSelectedFeatureKey = useWithDispatch(updateSelectedFeatureKeyByMapId);

  const sensorConfiguration = useSelectorWithReplayMode(
    selectSensorOrSubSensorConfigurationByUniqueCodes,
    sensorUniqueCodes,
  );

  const maintainAction = {
    action: ActionEnum.MAINTAIN,
    sensorFamily: sensorConfiguration !== null ? sensorFamilyType[sensorConfiguration.type] : null,
  };
  const hasRoleSensorMaintenance = useAppSelector((state) => hasSensorRoleFromActiveProfile(state, maintainAction));

  const sensorStatus = useSelectorWithReplayMode(selectSensorOrSubSensorStatusByUniqueCodes, sensorUniqueCodes);

  const parentSensorConfiguration = useSelectorWithReplayMode(
    selectSensorConfigurationByUniqueCode,
    sensorUniqueCodes.parentUniqueCode,
  );

  const handleOpenPopup = useCallback(
    (popupType: PopupDataTypeEnum, dataType: FeatureTypeEnum | SubSensorTypeEnum) => {
      updatePopupControl({
        mapId,
        popupControl: {
          type: popupType,
          open: true,
          position: { x: clickPosition?.x ?? 500, y: clickPosition?.y ?? 500 },
          data: {
            type: dataType,
            featureValue: sensorUniqueCodes,
          },
        } as PopupControlType,
      });
      onClose();
    },
    [updatePopupControl, mapId, clickPosition?.x, clickPosition?.y, sensorUniqueCodes, onClose],
  );

  const cameraMenuItems = useCameraSensorContextMenu(sensorConfiguration, sensorStatus, handleOpenPopup);
  const automatonMenuItems = useAutomatonSensorContextMenu(sensorConfiguration, sensorStatus);
  const cabinetMenuItems = useAutomatonCabinetSensorContextMenu(
    sensorConfiguration,
    sensorStatus,
    parentSensorConfiguration,
  );

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleClick = (option: string, value: MenuItemOption | undefined, depth: number) => {
    const temp = structuredClone(selectedOptions).slice(0, depth + 1);
    // click on same place => revert navigation
    if (selectedOptions[depth] === option) {
      setSelectedOptions(temp.slice(0, depth));
    } else {
      // else forward navigation
      setSelectedOptions(temp.toSpliced(depth, 1, option));
      // trigger all potential action linked to menu btn
      if (value?.onAction) {
        value.onAction();
        //resetNavigation
        setSelectedOptions(temp.slice(0, depth));
      }
    }
  };

  const isSubSensor = 'parentUniqueCode' in sensorUniqueCodes && !!parentSensorConfiguration;
  const subSensorType = isSubSensor ? (sensorConfiguration as SubSensorConfiguration).type : null;

  const isSensorActive = isSubSensor
    ? parentSensorConfiguration.active && sensorConfiguration?.active
    : sensorConfiguration?.active;

  const isMaintenanceInherited = isSubSensor ? parentSensorConfiguration.maintenance : false;
  const isSensorMaintenance = isMaintenanceInherited || sensorConfiguration?.maintenance;

  const [patchActivation, { isLoading: isActivationLoading }] = useUpdateSensorActivationMutation();
  const [patchMaintenance, { isLoading: isMaintenanceLoading }] = useUpdateSensorMaintenanceMutation();

  const handleSelectSensor = () => {
    updateSelectedFeatureKey({
      mapId,
      selectedFeatureKey: {
        type: SelectedFeatureTypeEnum.SENSOR,
        selectedSensorUniqueCodes: sensorUniqueCodes,
      },
    });
  };

  const handleOpenSensorInfo = () => {
    handleOpenPopup(
      PopupDataTypeEnum.INFO,
      type === SubSensorTypeEnum.AUTOMATON_CABINET ? SubSensorTypeEnum.AUTOMATON_CABINET : FeatureTypeEnum.SENSOR_ICON,
    );
    handleSelectSensor();
  };

  const sensorTypeToMeasureOrigin = (sensorConfig: typeof sensorConfiguration): MeasureOriginEnum => {
    if (sensorConfig === null) {
      return MeasureOriginEnum.OTHER_SENSOR;
    }
    switch (sensorConfig.type) {
      case SensorTypeEnum.AUTOMATON:
        return MeasureOriginEnum.API;
      case SubSensorTypeEnum.AUTOMATON_CABINET:
        return MeasureOriginEnum.BT;
      case SensorTypeEnum.ZMER:
      case SensorTypeEnum.HOLOPTICS:
        return MeasureOriginEnum.CAMERA;
      default:
        return MeasureOriginEnum.OTHER_SENSOR;
    }
  };

  const sensorPosition: Coordinates | null = sensorConfiguration
    ? 'position' in sensorConfiguration
      ? sensorConfiguration.position
      : sensorConfiguration.sensorPosition
    : null;
  const commonMenuItems = useCommonContextMenuItems({
    closeContextMenu: onClose,
    position: sensorPosition,
    measureOrigin: sensorTypeToMeasureOrigin(sensorConfiguration),
  });

  if (!sensorConfiguration) {
    return null;
  }

  const createActivationToast = (success: boolean) => {
    createToast(
      formatMessage(
        {
          id: isSensorActive ? 'contextmenu.actions.disconnectToast' : 'contextmenu.actions.connectToast',
        },
        {
          success,
          subSensorType: formatMessage(
            { id: `contextmenu.actions.subSensorType` },
            {
              subSensorType: subSensorType,
            },
          ),
          name: sensorConfiguration.name,
        },
      ),
      success ? ToastStatusEnum.SUCCESS : ToastStatusEnum.ERROR,
    );
  };

  const toggleActive = () => {
    let params;
    if (isSubSensor) {
      params = {
        id: parentSensorConfiguration.id,
        site: parentSensorConfiguration.site,
        appCode: parentSensorConfiguration.appCode,
        active: !isSensorActive,
        type: (sensorConfiguration as SubSensorConfiguration).type,
        codes: [sensorConfiguration.code],
      };
    } else {
      params = {
        id: sensorConfiguration.id,
        site: (sensorConfiguration as SensorConfiguration).site,
        appCode: sensorConfiguration.appCode,
        active: !isSensorActive,
      };
    }

    patchActivation(params)
      .unwrap()
      .then(() => createActivationToast(true))
      .catch(() => createActivationToast(false));
  };

  const createMaintenanceToast = (success: boolean) => {
    createToast(
      formatMessage(
        {
          id: isSensorMaintenance
            ? 'contextmenu.actions.sensorMaintenanceOffToast'
            : 'contextmenu.actions.sensorMaintenanceOnToast',
        },
        {
          success: success,
          subSensorType: formatMessage(
            { id: `contextmenu.actions.subSensorType` },
            {
              subSensorType: subSensorType,
            },
          ),
          name: sensorConfiguration.name,
        },
      ),
      success ? ToastStatusEnum.SUCCESS : ToastStatusEnum.ERROR,
    );
  };

  const toggleMaintenance = () => {
    let params;
    if (isSubSensor) {
      params = {
        id: parentSensorConfiguration.id,
        site: parentSensorConfiguration.site,
        appCode: parentSensorConfiguration.appCode,
        maintenance: !isSensorMaintenance,
        type: (sensorConfiguration as SubSensorConfiguration).type,
        codes: [sensorConfiguration.code],
      };
    } else {
      params = {
        id: sensorConfiguration.id,
        site: (sensorConfiguration as SensorConfiguration).site,
        appCode: sensorConfiguration.appCode,
        maintenance: !isSensorMaintenance,
      };
    }

    patchMaintenance(params)
      .unwrap()
      .then(() => createMaintenanceToast(true))
      .catch(() => createMaintenanceToast(false));
  };

  const menuItems: MenuItems = {
    openSensorInfo: {
      label: (
        <FormattedMessage
          id="contextmenu.actions.sensorInfo"
          values={{
            type: formatMessage(
              { id: `contextmenu.actions.type` },
              {
                type: sensorConfiguration.type,
              },
            ),
          }}
        />
      ),
      isDisabled:
        popupControl.open &&
        popupControl.type === PopupDataTypeEnum.INFO &&
        (FeatureTypeEnum.SENSOR_ICON === popupControl.data.type ||
          FeatureTypeEnum.SENSOR_LABEL === popupControl.data.type) &&
        (popupControl.data as SensorInfoData).featureValue.sensorUniqueCode ===
          getSensorUniqueCode(sensorConfiguration),
      onAction: handleOpenSensorInfo,
    },
    ...cameraMenuItems,
    ...automatonMenuItems,
    ...cabinetMenuItems,
    maintenanceSwitch: {
      label: `contextmenu.actions.${isSensorMaintenance ? 'maintenanceOff' : 'maintenanceOn'}`,
      isHidden: !hasRoleSensorMaintenance,
      isLoading: isMaintenanceLoading,
      isDisabled: !isSensorActive || isMaintenanceInherited,
      onAction: toggleMaintenance,
    },
    connectionSwitch: {
      label: (
        <FormattedMessage
          id="contextmenu.actions.connect"
          values={{
            connected: isSensorActive,
          }}
        />
      ),
      isHidden: !hasRoleSensorMaintenance,
      isLoading: isActivationLoading,
      onAction: toggleActive,
    },
    ...commonMenuItems,
  };

  return (
    <VStack gap={0}>
      <MenuList menuItems={menuItems} depth={0} selectedOptions={selectedOptions} handleClick={handleClick} />
    </VStack>
  );
}

export default SensorContextMenu;
