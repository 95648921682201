import { AVAILABLE_LANGUAGES, ControlledCamera, GlobalState } from '@redux/global/global.reducer';
import { RootState } from '@redux/store';
import { createSelector } from '@reduxjs/toolkit';

import { HoloLog } from '@/types/c2/logs.type';
import { SubCameraConfiguration } from '@/types/sensor/configuration.types';
import { TrackingStatusEnum, VideoTrackerState } from '@/types/sensor/status.types';

import { alertAdapter, logsAdapter } from './global.adapters';

function selectGlobal(state: RootState): GlobalState {
  return state.global;
}

export const selectLocaleValue = createSelector(
  selectGlobal,
  (state: GlobalState): AVAILABLE_LANGUAGES => state.locale,
);

export const selectSelectedCameraCode = createSelector(
  selectGlobal,
  (state: GlobalState): string | null => state.selectedCameraCode,
);

export const selectJoystickControlId = createSelector(
  selectGlobal,
  (state: GlobalState): string | null => state.joystickControlId,
);

export const selectSelectedDoubtCheckSegmentName = createSelector(
  selectGlobal,
  (state: GlobalState): string | null => state.selectedDoubtCheckSegmentName,
);

export const selectSelectedDoubtCheckPerimeterCameraUniqueCode = createSelector(
  selectGlobal,
  (state: GlobalState): string | null => state.selectedDoubtCheckPerimeterCameraUniqueCode,
);

export const selectJoystickControlledCam = createSelector(
  selectGlobal,
  (state: GlobalState): ControlledCamera => state.joystickControlledCam,
);

export const { selectAll: selectAlertQueue } = alertAdapter.getSelectors(
  (state: RootState) => selectGlobal(state).alertQueue,
);

export const selectTrackingStateOfCameras = createSelector(
  selectGlobal,
  (state: GlobalState): VideoTrackerState[] => state.videoTrackerState,
);

export const selectTrackingStateOfCamera = (cameraCode: string | null) =>
  createSelector(
    selectGlobal,
    (state: GlobalState): VideoTrackerState | null =>
      state.videoTrackerState.find((value) => value.cameraCode === cameraCode) ?? null,
  );

export const selectTrackingStateOfCameraFlux = (cameraCode: string | null, fluxId?: number) =>
  createSelector(
    selectTrackingStateOfCamera(cameraCode),
    (cameraState: VideoTrackerState | null): TrackingStatusEnum | null =>
      cameraState?.fluxStates.find((value) => value.fluxId === fluxId)?.state ?? null,
  );

export const selectNewLogBookEntry = createSelector(
  selectGlobal,
  (state: GlobalState): boolean => state.newLogBookEntry,
);

export const selectSelectedCameraStreams = createSelector(
  selectGlobal,
  (state: GlobalState): SubCameraConfiguration[] => state.selectedCameraStreams,
);

export const { selectAll: selectAllLogs } = logsAdapter.getSelectors((state: RootState) => state.global.logs);

export const selectLogByCode = logsAdapter.getSelectors((state: RootState) => state.global.logs).selectById as (
  state: RootState,
  id: string,
) => HoloLog | undefined;
