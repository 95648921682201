import { Box, HStack, VStack } from '@chakra-ui/layout';
import { Button, TabPanel, Text, useBoolean } from '@chakra-ui/react';
import GroupSelect from '@components/cockpit/panels/admin/GroupSelect';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectDenormalizedUsers } from '@redux/authent/authent.selectors';
import { useDeleteUserMutation } from '@services/authent/user.api';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import React, { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import UserForm from './UserForm';

function Users() {
  const { formatMessage } = useIntl();
  const users = useAppSelector(selectDenormalizedUsers);
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);
  const [editMode, setEditMode] = useBoolean(false);
  const [deleteUser] = useDeleteUserMutation();

  return (
    <TabPanel height="100%" backgroundColor="white" padding={3}>
      <HStack alignItems="start" gap={3}>
        <HStack>
          <VStack gap={3} alignItems="start">
            <Text color="neutral.black">
              <FormattedMessage id="components.admin.users.subTitle" />
            </Text>
            <UserForm
              user={users.find((user) => selectedUserId === user.id)}
              editMode={editMode}
              key={`${editMode}`}
              onReset={setEditMode.off}
            />
            <HStack>
              <VStack
                width="300px"
                border="1px solid"
                padding={0.5}
                alignItems="start"
                gap={0.25}
                height="180px"
                overflowY="auto"
              >
                {users.map((user) => (
                  <Box
                    key={user.id}
                    pointerEvents="all"
                    cursor="pointer"
                    onClick={() => {
                      setEditMode.off();
                      setSelectedUserId((prev) => (prev === user.id ? undefined : user.id));
                    }}
                  >
                    <Text
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      color="neutral.black"
                      fontWeight={selectedUserId === user.id ? '700' : 'regular'}
                      paddingLeft={2}
                      paddingRight={2}
                    >
                      {`${user.name.toUpperCase()} ${user.firstName}`}
                    </Text>
                  </Box>
                ))}
              </VStack>
              <VStack height="200px">
                <Button isDisabled={selectedUserId === undefined} width="100%" onClick={setEditMode.toggle}>
                  {editMode ? <FormattedMessage id="global.cancel" /> : <FormattedMessage id="global.update" />}
                </Button>
                <Button
                  width="100%"
                  isDisabled={!selectedUserId}
                  onClick={() => {
                    if (selectedUserId) {
                      deleteUser(selectedUserId)
                        .unwrap()
                        .then(() => {
                          setSelectedUserId(undefined);
                          createToast(
                            formatMessage({ id: 'components.admin.users.message.delete' }),
                            ToastStatusEnum.SUCCESS,
                          );
                        });
                    }
                  }}
                >
                  <FormattedMessage id="global.delete" />
                </Button>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
        <GroupSelect users={users} />
      </HStack>
    </TabPanel>
  );
}

export default memo(Users);
