import { SensorTypeEnum } from '../sensor/configuration.types';

export interface DictionaryNum<T> {
  [id: number]: T | undefined;
}

//TODO: Replace all Dictionary with records
export interface Dictionary<T> extends DictionaryNum<T> {
  [id: string]: T | undefined;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
  type: string;
}

export interface Coordinates3D extends Coordinates {
  altitude: number;
}

export interface Abstract {
  createdBy: string | null;
  creationTime: string | null;
  modifiedBy: string | null;
  modificationTime: string | null;
}

export interface TrackLight {
  id: string;
  sensorCode: string;
  subSensorCode?: string;
  sensorType: SensorTypeEnum;
  appCode: string;
}

export interface StatusPayload {
  status: string;
}

export interface SyriusExceptionPayload {
  data: SyriusException;
}

export interface FirstConnectionExceptionPayload {
  status: number;
  data: {
    first_name: string;
    name: string;
    login: string;
    email: string;
    user_id: string;
    code: string;
    user_state: UserStateEnum;
  };
}

export enum UserStateEnum {
  FIRST_TIME = 'FIRST_TIME',
  NORMAL = 'NORMAL',
  PASSWORD_TO_REINITIALIZE = 'PASSWORD_TO_REINITIALIZE',
}

export interface Param {
  value: string;
  i18nKey: string;
}

export interface SyriusException {
  message: string;
  code: string;
  params: Param[];
}

export enum CoordinatesUnitEnum {
  DECIMAL_DEGREES = 'DECIMAL_DEGREES',
  DMS = 'DMS',
}

export interface WithHeight {
  minHeightFinite: boolean;
  maxHeightFinite: boolean;
  heightMin: number;
  heightMax: number;
}

export interface WithMargin {
  marginSize: number;
  margin: Coordinates[];
}

export interface ImportItem {
  type: ImportItemTypeEnum;
  code: string;
  appCode: string;
  operation: ImportItemOperationEnum;
  unknownVersion: boolean;
  fail: boolean;
}

export interface ImportChoiceItem {
  type: ImportItemTypeEnum;
  code: string;
  newCode?: string;
  appCode: string;
  newAppCode?: string;
  ignore: boolean;
}

export interface DurationCriteria {
  site: string;
  from?: string;
  to?: string;
}

export enum ImportItemTypeEnum {
  HOME = 'HOME',
  NO_FLY_ZONE = 'NO_FLY_ZONE',
  INITIALIZATION_MASKING_ZONE = 'INITIALIZATION_MASKING_ZONE',
  NO_NEUTRALIZATION_ZONE = 'NO_NEUTRALIZATION_ZONE',
  POINT_OF_INTEREST = 'POINT_OF_INTEREST',
  ZONE_OF_INTEREST = 'ZONE_OF_INTEREST',
  VIDEO_STREAM = 'VIDEO_STREAM',
  SENSOR = 'SENSOR',
  FLIGHT_PLAN = 'FLIGHT_PLAN',
  DRONE = 'DRONE',
  PILOT = 'PILOT',
  FLY_ZONE = 'FLY_ZONE',
  FLY_SECTION = 'FLY_SECTION',
  FLY_ROUTE = 'FLY_ROUTE',
  VIDEO = 'VIDEO',
}

new Map<ImportItemTypeEnum, string>([
  [ImportItemTypeEnum.HOME, 'home'],
  [ImportItemTypeEnum.NO_FLY_ZONE, 'noFlyZones'],
  [ImportItemTypeEnum.INITIALIZATION_MASKING_ZONE, 'initializationMaskingZones'],
  [ImportItemTypeEnum.NO_NEUTRALIZATION_ZONE, 'noNeutralizationZones'],
  [ImportItemTypeEnum.POINT_OF_INTEREST, 'pointOfInterests'],
  [ImportItemTypeEnum.ZONE_OF_INTEREST, 'zoneOfInterests'],
  [ImportItemTypeEnum.VIDEO_STREAM, 'videoStreams'],
  [ImportItemTypeEnum.SENSOR, 'sensors'],
  [ImportItemTypeEnum.FLIGHT_PLAN, 'flightPlans'],
  [ImportItemTypeEnum.DRONE, 'drones'],
  [ImportItemTypeEnum.PILOT, 'pilots'],
  [ImportItemTypeEnum.FLY_ZONE, 'flyZones'],
  [ImportItemTypeEnum.FLY_SECTION, 'flySections'],
  [ImportItemTypeEnum.FLY_ROUTE, 'flyRoutes'],
  [ImportItemTypeEnum.VIDEO, 'videoConfiguration'],
]);

export enum ImportItemOperationEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum ZoneExportFormat {
  KML = 'KML',
  GEOJSON = 'GEOJSON',
}

export enum DroneModelEnum {
  MAV_TYPE_GENERIC = 'GENERIC',
  MAV_TYPE_FIXED_WING = 'FIXED WING',
  MAV_TYPE_QUADROTOR = 'QUADROTOR',
  MAV_TYPE_COAXIAL = 'COAXIAL',
  MAV_TYPE_HELICOPTER = 'HELICOPTER',
  MAV_TYPE_ANTENNA_TRACKER = 'ANTENNA TRACKER',
  MAV_TYPE_GCS = 'GCS',
  MAV_TYPE_AIRSHIP = 'AIRSHIP',
  MAV_TYPE_FREE_BALLOON = 'BALLOON',
  MAV_TYPE_ROCKET = 'ROCKET',
  MAV_TYPE_GROUND_ROVER = 'ROVER',
  MAV_TYPE_SURFACE_BOAT = 'BOAT',
  MAV_TYPE_SUBMARINE = 'SUBMARINE',
  MAV_TYPE_HEXAROTOR = 'HEXAROTOR',
  MAV_TYPE_OCTOROTOR = 'OCTOROTOR',
  MAV_TYPE_TRICOPTER = 'TRICOPTER',
  MAV_TYPE_FLAPPING_WING = 'FLAPPING WING',
  MAV_TYPE_ONBOARD_CONTROLLER = 'ONBOARD CONTROLLER',
  MAV_TYPE_VTOL_TAILSITTER_DUOROTOR = 'VTOL DUOROTOR',
  MAV_TYPE_VTOL_TAILSITTER_QUADROTOR = 'VTOL QUADROTOR',
  MAV_TYPE_VTOL_TILTROTOR = 'VTOL TILTROTOR',
  MAV_TYPE_VTOL_FIXEDROTOR = 'VTOL FIXEDROTOR',
  MAV_TYPE_VTOL_TAILSITTER = 'VTOL TAILSITTER',
  MAV_TYPE_VTOL_TILTWING = 'VTOL_TILTWING',
  MAV_TYPE_VTOL_RESERVED5 = 'VTOL RESERVED',
  MAV_TYPE_GIMBAL = 'GIMBAL',
  MAV_TYPE_ADSB = 'ADSB',
  MAV_TYPE_PARAFOIL = 'PARAFOIL',
  MAV_TYPE_DODECAROTOR = 'DODECAROTOR',
  MAV_TYPE_CAMERA = 'CAMERA',
  MAV_TYPE_CHARGING_STATION = 'CHARGING STATION',
  MAV_TYPE_FLARM = 'FLARM',
  MAV_TYPE_SERVO = 'SERVO',
  MAV_TYPE_ODID = 'ODID',
  MAV_TYPE_DECAROTOR = 'DECAROTOR',
  MAV_TYPE_BATTERY = 'BATTERY',
  MAV_TYPE_PARACHUTE = 'PARACHUTE',
  MAV_TYPE_LOG = 'LOG',
  MAV_TYPE_OSD = 'OSD',
  MAV_TYPE_IMU = 'IMU',
  MAV_TYPE_GPS = 'GPS',
  MAV_TYPE_WINCH = 'WINCH',
}

export enum VideoProtocolEnum {
  RTSP = 'RTSP',
  SLDP = 'SLDP',
}

export interface Stream {
  code: string;
  name: string;
  sourceUrl: string | null;
  sourceProtocol: VideoProtocolEnum | null;
  url: string | null;
  protocol: VideoProtocolEnum | null;
}

export interface ExternalReplayStream {
  sourceReplayUrl: string | null;
  replayUrl: string | null;
}

export type Modify<T, R> = Omit<T, keyof R> & R;

export enum MarkEnum {
  STARTED_ABOVE_GROUND = 'STARTED_ABOVE_GROUND',
  STARTED_UNDERGROUND = 'STARTED_UNDERGROUND',
  STARTED_IN_NO_INIT_ZONE = 'STARTED_IN_NO_INIT_ZONE',
  STARTED_OUT_NO_INIT_ZONE = 'STARTED_OUT_NO_INIT_ZONE',
  STARTED_IN_INIT_MASK_ZONE = 'STARTED_IN_INIT_MASK_ZONE',
  STARTED_OUT_INIT_MASK_ZONE = 'STARTED_OUT_INIT_MASK_ZONE',
  LEFT_INIT_MASK_ZONE = 'LEFT_INIT_MASK_ZONE',
  STARTED_IN_ELEVATION_APERTURE_RANGE = 'STARTED_IN_ELEVATION_APERTURE_RANGE',
  STARTED_OUT_ELEVATION_APERTURE_RANGE = 'STARTED_OUT_ELEVATION_APERTURE_RANGE',
  STATIC_APPROACH = 'STATIC_APPROACH',
  MOVING_APPROACH = 'MOVING_APPROACH',
  LINEAR = 'LINEAR',
  VERTICAL = 'VERTICAL',
  CIRCULAR = 'CIRCULAR',
  CRISIS = 'CRISIS',
  PLATFORM = 'PLATFORM',
}

export interface Mark {
  id: number;
  type: MarkEnum;
  context: string | null;
}

export enum ActionEnum {
  ACCESS = 'ACCESS',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  COMMAND = 'COMMAND',
  MAINTAIN = 'MAINTAIN',
  TAKE_STANDARD_CONTROL = 'TAKE_STANDARD_CONTROL',
  TAKE_PREMIUM_CONTROL = 'TAKE_PREMIUM_CONTROL',
  ACTIVATE_REPULSION = 'ACTIVATE_REPULSION',
  DEACTIVATE_REPULSION = 'DEACTIVATE_REPULSION',
}
