import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { hasSensorRoleFromActiveProfile, selectSessionUuid } from '@redux/authent/authent.selectors';
import { isCameraType } from '@utils/sensors/camera/camera.utils';

import { ActionEnum } from '@/types/commons/commons.types';
import { FeatureTypeEnum, MapIdEnum, PopupDataTypeEnum } from '@/types/map.types';
import { MenuItems } from '@/types/menu.types';
import { SensorConfiguration, SubSensorConfiguration, SubSensorTypeEnum } from '@/types/sensor/configuration.types';
import { SensorFamilyEnum } from '@/types/sensor/sensor.types';
import { CameraStatus, SensorStatus, SubSensorStatus } from '@/types/sensor/status.types';

export function useCameraSensorContextMenu(
  configuration: SensorConfiguration | SubSensorConfiguration | null,
  status: SubSensorStatus | SensorStatus | null,
  handleOpenPopup: (popupType: PopupDataTypeEnum, dataType: FeatureTypeEnum | SubSensorTypeEnum) => void,
): MenuItems | null {
  const sessionUuid = useAppSelector(selectSessionUuid);
  const { mapId } = useMapContext();

  const isCameraConfiguration = configuration && isCameraType(configuration as SensorConfiguration);
  const isCameraStatus = !status || isCameraType(status as SensorStatus);
  const commandAction = { action: ActionEnum.COMMAND, sensorFamily: SensorFamilyEnum.CAMERA };
  const canSendCameraCommands = useAppSelector((state) => hasSensorRoleFromActiveProfile(state, commandAction));

  if (!canSendCameraCommands || !isCameraConfiguration || !isCameraStatus) {
    return null;
  }
  const cameraStatus = status as CameraStatus | null;

  return {
    camDoingRound: {
      label: 'contextmenu.actions.round',
      isHidden: !status || mapId === MapIdEnum.REPLAY,
      isDisabled: cameraStatus?.cameraToken?.cameraOperatorUuid !== sessionUuid,
      onAction: () => handleOpenPopup(PopupDataTypeEnum.ROUND, FeatureTypeEnum.SENSOR_ICON),
    },
  };
}
