import { ActionEnum, Coordinates, Coordinates3D } from '../commons/commons.types';
import { Identification } from './identification.types';

export enum LocationTypeEnum {
  LINEAR = 'LINEAR',
  PLANAR = 'PLANAR',
  SPATIAL = 'SPATIAL',
  SPATIAL_LINEAR = 'SPATIAL_LINEAR',
}

export const SENSOR_LABEL_ICON_OFFSET_PX = [0, 45];
export const SENSOR_LABEL_TEXT_OFFSET_PX_L = [0, 3.3];
export const SENSOR_LABEL_TEXT_OFFSET_PX_M = [0, 2.6];
export const CAMERA_PERIM_LABEL_TEXT_OFFSET_PX = [0, 0.1];

export interface PlanarLocation extends PlanarLocationOptions {
  type: LocationTypeEnum.PLANAR;
}

export interface PlanarLocationOptions {
  position: Coordinates | null;
  horizontalPrecision: number | null;
  /** Distance from home in meters. */
  distanceFromHome: number | null;
  speed: number | null;
  heading: number | null;
  /** Horizontal speed direction towards North. */
  yaw: number | null;
}

export interface LinearLocation extends LinearLocationOptions {
  type: LocationTypeEnum.LINEAR;
}

export interface LinearLocationOptions {
  center: Coordinates;
  /** Azimuth towards North. */
  azimuth: number | null;
  azimuthAccuracy: number | null;
  range: number | null;
  rssi: number | null;
}

export interface SpatialLinearLocation extends LinearLocationOptions, SpatialLinearLocationOptions {
  type: LocationTypeEnum.SPATIAL_LINEAR;
}

export interface SpatialLinearLocationOptions {
  /** Tilt towards ground. */
  tilt: number | null;
  tiltPrecision: number | null;
}

export interface SpatialLocation extends PlanarLocationOptions, SpatialLocationOptions {
  type: LocationTypeEnum.SPATIAL;
}

export interface SpatialLocationOptions {
  /** Height from the local ground in meters. */
  height: number | null;
  /** Whether the height is computed by the server of transmitted by the sensor */
  computedHeight: boolean;
  /** Altitude from the sea level in meters. */
  altitude: number | null;
  /** Whether the altitude is computed by the server of transmitted by the sensor */
  computedAltitude: boolean;
  verticalPrecision: number | null;
  /** Vertical speed direction towards ground. */
  pitch: number | null;
}

export type StrobeLocation = LinearLocation | SpatialLinearLocation;
export type TargetLocation = PlanarLocation | SpatialLocation;
export type AbstractLocation = StrobeLocation | TargetLocation;

export interface PlotLight<T extends AbstractLocation> {
  id: number;
  site: string;
  sensorCode: string;
  trackId: string;
  time: string;
  identification: Identification;
  location: T | null;
}

export interface Plot<T extends AbstractLocation> extends PlotLight<T> {
  collaborativeData?: CollaborativeData;
  remoteData?: RemoteData;
  rfData?: RfData;
  radarData?: RadarData;
  technicalData?: TechnicalData;
}

export interface CollaborativeData {
  aeroscopeId?: string;
  aircraftId?: string;
  infodroneId?: string;
  vehicleId?: string;
  model?: string;
  fleet?: string;
}

export interface RemoteData {
  droneId?: string;
  remoteId?: string;
}

export interface RfData {
  frequency?: number;
  rssi?: number;
  protocol?: string;
}

export interface RadarData {
  rcs?: number;
  doppler?: number;
  microDoppler?: number;
}

export interface TechnicalData {
  batteryRemaining: number | null;
  droneModel: string;
  flyingMode: string;
  homePosition: Coordinates3D | null;
  landedState: LandedStateEnum | null;
}

export enum LandedStateEnum {
  MAV_LANDED_STATE_UNDEFINED = 'MAV_LANDED_STATE_UNDEFINED',
  MAV_LANDED_STATE_ON_GROUND = 'MAV_LANDED_STATE_ON_GROUND',
  MAV_LANDED_STATE_IN_AIR = 'MAV_LANDED_STATE_IN_AIR',
  MAV_LANDED_STATE_TAKEOFF = 'MAV_LANDED_STATE_TAKEOFF',
  MAV_LANDED_STATE_LANDING = 'MAV_LANDED_STATE_LANDING',
}

export enum SensorFamilyEnum {
  AUTOMATON = 'AUTOMATON',
  CAMERA = 'CAMERA',
  COLLABORATIVE = 'COLLABORATIVE',
  NEUTRAL = 'NEUTRAL',
  RADAR = 'RADAR',
  RID = 'RID',
  RF = 'RF',
}
export interface SensorAction {
  action: ActionEnum | null | undefined;
  sensorFamily: SensorFamilyEnum | null | undefined;
}
