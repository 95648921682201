import { VStack } from '@chakra-ui/react';
import MenuList from '@components/common/menu/MenuList';
import { useMapContext } from '@components/map/MapContext';
import { useAppSelector } from '@hooks/redux.hooks';
import { useCameraCommand } from '@hooks/useCameraCommand';
import { MeasureOriginEnum, useCommonContextMenuItems } from '@hooks/useCommonContextMenuItems';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { useWithDispatch } from '@hooks/useWithDispatch';
import {
  hasSensorRoleFromActiveProfile,
  selectActiveUser,
  selectGlobalScreenConfigurationCode,
} from '@redux/authent/authent.selectors';
import { GlobalScreenConfigurationSelectors } from '@redux/config/config.selectors';
import { updateSelectedCameraCode, updateSelectedDoubtCheckSegmentName } from '@redux/global/global.reducer';
import { selectSelectedDoubtCheckSegmentName } from '@redux/global/global.selector';
import { updatePopupControlByMapId } from '@redux/maps/maps.reducer';
import { selectPopupControlByMapId } from '@redux/maps/maps.selectors';
import { selectContext } from '@redux/settings/settings.selectors';
import {
  selectAutomatonSectionByName,
  selectClosestActiveHealthyCamera,
  selectHealthyCameraStatuses,
  selectLowerStatesByName,
  selectSectionStatusByName,
  selectUpperStatesByName,
} from '@redux/situation/monitoring.selectors';
import {
  usePostAutomatonCommandMutation,
  useUpdateSensorActivationMutation,
  useUpdateSensorMaintenanceMutation,
} from '@services/c2/c2.api';
import { usePatchContextMutation } from '@services/config/setting.api';
import {
  getAutomatonSectionNames,
  getSectionNameAndSegments,
  getSectionStartAndEndPosition,
  isSectionActive,
  isSectionInMaintenance,
  isSegmentActive,
  sendRepulsionCommand,
} from '@utils/map/automatonSection.utils';
import { getContextMenuCameraLabel } from '@utils/sensors/camera/camera.utils';
import { getSensorUniqueCode } from '@utils/sensors/sensors.utils';
import { createToast, ToastStatusEnum } from '@utils/toast.utils';
import { toCoordinates } from '@utils/validation/coordinates.utils';
import { LngLat } from 'mapbox-gl';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  AutomatonCommandEnum,
  AutomatonSectionDefenceCommand,
  AutomatonSegmentDefenceCommand,
} from '@/types/c2/c2.types';
import { ActionEnum, Coordinates } from '@/types/commons/commons.types';
import { ContextEnum } from '@/types/config/screenConfiguration.types';
import { AutomatonSectionInfoData, FeatureTypeEnum, PopupDataTypeEnum, SensorInfoData } from '@/types/map.types';
import { MenuItemOption, MenuItems } from '@/types/menu.types';
import { AutomatonSectionNames, AutomatonSegment, SubSensorTypeEnum } from '@/types/sensor/configuration.types';
import { SensorFamilyEnum } from '@/types/sensor/sensor.types';
import { AutomatonNodeStateEnum, CameraStatus, SensorStatusEnum } from '@/types/sensor/status.types';

export type AutomatonSectionContextMenuProps = {
  clickPosition: { x: number; y: number } | null;
  position: LngLat | undefined;
  automatonSectionNames: AutomatonSectionNames;
  onClose: () => void;
};

export default function AutomatonSectionContextMenu({
  automatonSectionNames,
  clickPosition,
  position,
  onClose,
}: Readonly<AutomatonSectionContextMenuProps>) {
  const { mapId } = useMapContext();
  const { formatMessage } = useIntl();

  const value = useSelectorWithReplayMode(selectAutomatonSectionByName, automatonSectionNames.sectionName)!;
  const { automaton, cabinet, section } = value;

  const popupControl = useAppSelector((state) => selectPopupControlByMapId(state, mapId));
  const healthyLadCameras = useAppSelector(selectHealthyCameraStatuses);
  const closestLADCamera = useSelectorWithReplayMode(selectClosestActiveHealthyCamera, toCoordinates(position));
  const selectedDoubtCheckSegmentName = useAppSelector(selectSelectedDoubtCheckSegmentName);

  const hasAccessCamCommand = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, { action: ActionEnum.COMMAND, sensorFamily: SensorFamilyEnum.CAMERA }),
  );
  const hasRolePeriSensorCommand = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, { action: ActionEnum.COMMAND, sensorFamily: SensorFamilyEnum.AUTOMATON }),
  );
  const hasRoleSensorMaintenance = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, { action: ActionEnum.MAINTAIN, sensorFamily: SensorFamilyEnum.AUTOMATON }),
  );
  const hasRoleRepulsionCommand = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, {
      action: ActionEnum.ACTIVATE_REPULSION,
      sensorFamily: SensorFamilyEnum.AUTOMATON,
    }),
  );
  const hasRoleDetectionCommand = useAppSelector((state) =>
    hasSensorRoleFromActiveProfile(state, {
      action: ActionEnum.DEACTIVATE_REPULSION,
      sensorFamily: SensorFamilyEnum.AUTOMATON,
    }),
  );

  const user = useAppSelector(selectActiveUser);
  const currentGlobalScreenConfigurationCode = useAppSelector(selectGlobalScreenConfigurationCode);
  const currentContext = useAppSelector(selectContext);
  const globalScreenConfiguration =
    useAppSelector((state) =>
      GlobalScreenConfigurationSelectors.selectGlobalScreenConfigurationByCode(
        state,
        currentGlobalScreenConfigurationCode,
      ),
    ) ?? null;
  const doubtCheckContextIsPresent = !!globalScreenConfiguration?.screenConfigurations.some(
    (screenConfig) => screenConfig.context === ContextEnum.DOUBT_PERIM && screenConfig.gridLayoutCode,
  );

  const { name, segments } = getSectionNameAndSegments(section);

  const upperSegment = section.upperSegment ?? null;
  const lowerSegment = section.lowerSegment ?? null;

  const lowerStates = useSelectorWithReplayMode(selectLowerStatesByName, name) ?? {};
  const upperStates = useSelectorWithReplayMode(selectUpperStatesByName, name) ?? {};
  const sectionStatus = useSelectorWithReplayMode(selectSectionStatusByName, lowerSegment?.name || upperSegment?.name);
  const updatePopupControl = useWithDispatch(updatePopupControlByMapId);
  const updateGlobalSelectedDoubtCheckSegmentName = useWithDispatch(updateSelectedDoubtCheckSegmentName);
  const updateGlobalSelectedCameraCode = useWithDispatch(updateSelectedCameraCode);
  const [postRepulsionCommand, { isLoading: isRepulsionCommandLoading }] = usePostAutomatonCommandMutation();
  const [patchMaintenance, { isLoading: isMaintenanceLoading }] = useUpdateSensorMaintenanceMutation();
  const [patchActivation, { isLoading: isActivationLoading }] = useUpdateSensorActivationMutation();

  const [patchContext] = usePatchContextMutation();

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const isLowerRepulsionOff = !!lowerStates[AutomatonNodeStateEnum.BT_DETECTION];
  const isUpperRepulsionOff = !!upperStates[AutomatonNodeStateEnum.BT_DETECTION];
  const isRepulsionOff = (!lowerSegment || isLowerRepulsionOff) && (!upperSegment || isUpperRepulsionOff);
  const canSwitchRepulsion = isRepulsionOff ? hasRoleRepulsionCommand : hasRoleDetectionCommand;

  const useCommand = useCameraCommand({
    cameraStatus: closestLADCamera,
  });

  const handleClick = (option: string, value: MenuItemOption | undefined, depth: number) => {
    const temp = structuredClone(selectedOptions).slice(0, depth + 1);
    // click on same place => revert navigation
    if (selectedOptions[depth] === option) {
      setSelectedOptions(temp.slice(0, depth));
    } else {
      // else forward navigation
      setSelectedOptions(temp.toSpliced(depth, 1, option));
      // trigger all potential action linked to menu btn
      if (value?.onAction) {
        value.onAction();
      }
    }
  };

  const handleOpenSectionInfo = useCallback(() => {
    updatePopupControl({
      mapId: mapId,
      popupControl: {
        type: PopupDataTypeEnum.INFO,
        open: true,
        position: { x: clickPosition?.x ?? 500, y: clickPosition?.y ?? 500 },
        data: {
          type: FeatureTypeEnum.AUTOMATON_SECTION_LINE,
          featureValue: getAutomatonSectionNames(value),
        } as AutomatonSectionInfoData,
      },
    });
    onClose();
  }, [updatePopupControl, mapId, value, clickPosition?.x, clickPosition?.y, onClose]);

  function handleOpenSectionCabinetInfo() {
    updatePopupControl({
      mapId: mapId,
      popupControl: {
        type: PopupDataTypeEnum.INFO,
        open: true,
        position: { x: clickPosition?.x ?? 500, y: clickPosition?.y ?? 500 },
        data: {
          type: SubSensorTypeEnum.AUTOMATON_CABINET,
          featureValue: {
            sensorUniqueCode: getSensorUniqueCode(cabinet),
            parentUniqueCode: getSensorUniqueCode(automaton),
          },
        } as SensorInfoData,
      },
    });
    onClose();
  }

  // On prend arbitrairement le segment du haut. En théorie les 2 segments ont les mêmes caméras liées, mais en
  // pratique Jonathan les a mis que sur le segment haut dans son fichier d'import :/
  const segmentWithCamera = (upperSegment?.name ?? lowerSegment?.name) as string;

  function openAttachedPerimeterCameras() {
    updateGlobalSelectedDoubtCheckSegmentName(segmentWithCamera);
    onClose();
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const segmentAltitude = ((lowerSegment ?? upperSegment) as AutomatonSegment).altitude ?? null;

  function handleLookAt(cameraStatus: CameraStatus) {
    if (position) {
      const sectionPosition = getSectionStartAndEndPosition(section);
      useCommand.lookAt(
        {
          latitude: position.lat,
          longitude: position.lng,
          type: '2D',
        },
        {
          longitude: sectionPosition.sectionStart[0],
          latitude: sectionPosition.sectionStart[1],
          type: '2D',
        },
        {
          longitude: sectionPosition.sectionEnd[0],
          latitude: sectionPosition.sectionEnd[1],
          type: '2D',
        },
        segmentAltitude,
        null,
        true,
        cameraStatus,
      );
      updateGlobalSelectedCameraCode(getSensorUniqueCode(cameraStatus));
      onClose();
    }
  }

  function handleSectionActivation(segments: AutomatonSegment[]) {
    const isActive = automaton.active && cabinet.active && segments.every((segment) => segment.active);
    patchActivation({
      id: automaton.id,
      site: automaton.site,
      appCode: automaton.appCode,
      active: !isActive,
      type: SubSensorTypeEnum.AUTOMATON_SEGMENT,
      codes: segments.map((segment) => segment.code),
    })
      .unwrap()
      .then(() => {
        const { code: code1, name } = segments.pop()!;
        const code2 = segments.pop()?.code;
        createToast(
          code2
            ? formatMessage(
                { id: 'contextmenu.actions.multipleActivationSuccess' },
                {
                  active: !isActive,
                  code1: code1,
                  code2: code2,
                  section: name,
                },
              )
            : formatMessage(
                { id: 'contextmenu.actions.activationSuccess' },
                {
                  active: !isActive,
                  code: code1,
                  section: name,
                },
              ),
          ToastStatusEnum.SUCCESS,
        );
      });
    onClose();
  }

  function handleSegmentRepulsion(enableRepulsion: boolean, segment: AutomatonSegment) {
    const command = {
      type: AutomatonCommandEnum.AUTOMATON_SEGMENT_COMMAND_DEFENCE,
      sensorCode: automaton.code,
      cabinetId: cabinet.nodeId,
      segmentId: segment.nodeId,
      defence: enableRepulsion,
      segmentType: segment.fenceType,
    } as AutomatonSegmentDefenceCommand;

    sendRepulsionCommand(
      command,
      postRepulsionCommand,
      enableRepulsion,
      name,
      true,
      automaton.site,
      automaton.appCode,
      segment.fenceType,
    );
    onClose();
  }

  function handleSectionRepulsion(enableRepulsion: boolean) {
    const command = {
      type: AutomatonCommandEnum.AUTOMATON_SECTION_COMMAND_DEFENCE,
      sensorCode: automaton.code,
      cabinetId: cabinet.nodeId,
      segmentId: segments[0].nodeId,
      defence: enableRepulsion,
    } as AutomatonSectionDefenceCommand;

    sendRepulsionCommand(
      command,
      postRepulsionCommand,
      enableRepulsion,
      name,
      false,
      automaton.site,
      automaton.appCode,
    );
    onClose();
  }

  function handleSectionMaintenance(segments: AutomatonSegment[]) {
    const isMaintenance = segments.some((segment) => segment.maintenance);
    patchMaintenance({
      id: automaton.id,
      site: automaton.site,
      appCode: automaton.appCode,
      maintenance: !isMaintenance,
      type: SubSensorTypeEnum.AUTOMATON_SEGMENT,
      codes: segments.map((segment) => segment.code),
    })
      .unwrap()
      .then(() => {
        const { code: code1, name } = segments.pop()!;
        const code2 = segments.pop()?.code;
        createToast(
          code2
            ? formatMessage(
                { id: 'contextmenu.actions.multipleMaintenanceSuccess' },
                {
                  maintenance: !isMaintenance,
                  code1: code1,
                  code2: code2,
                  section: name,
                },
              )
            : formatMessage(
                { id: 'contextmenu.actions.maintenanceSuccess' },
                {
                  maintenance: !isMaintenance,
                  code: code1,
                  section: name,
                },
              ),
          ToastStatusEnum.SUCCESS,
        );
      });
    onClose();
  }

  function setupForPerimeterDoubtCheckContext() {
    patchContext({ login: user.login, context: ContextEnum.DOUBT_PERIM });
    closestLADCamera && handleLookAt(closestLADCamera);
    openAttachedPerimeterCameras();
  }

  const sectionCenterPosition: Coordinates | null = lowerSegment?.position ?? upperSegment?.position ?? null;
  const commonMenuItems = useCommonContextMenuItems({
    closeContextMenu: onClose,
    position: sectionCenterPosition,
    measureOrigin: MeasureOriginEnum.PERIMETER_SECTION,
  });
  const menuItems: MenuItems = {
    openSectionInfo: {
      label: 'contextmenu.actions.sectionSheet',
      isDisabled:
        popupControl.open &&
        popupControl.type === PopupDataTypeEnum.INFO &&
        (popupControl.data.type === FeatureTypeEnum.AUTOMATON_SECTION_ENDING ||
          popupControl.data.type === FeatureTypeEnum.AUTOMATON_SECTION_ICON ||
          popupControl.data.type === FeatureTypeEnum.AUTOMATON_SECTION_LABEL ||
          popupControl.data.type === FeatureTypeEnum.AUTOMATON_SECTION_LINE) &&
        popupControl.data.featureValue.sectionName === name,
      onAction: handleOpenSectionInfo,
    },
    openCabinetInfo: {
      label: 'contextmenu.actions.cabinetSheet',
      isDisabled:
        popupControl.open &&
        popupControl.type === PopupDataTypeEnum.INFO &&
        popupControl.data.type === 'AUTOMATON_CABINET' &&
        popupControl.data.featureValue.sensorUniqueCode === cabinet.code,
      onAction: handleOpenSectionCabinetInfo,
    },
    switchRepulsionMode: {
      label: `contextmenu.actions.modifySection.${isRepulsionOff ? 'repulsionOn' : 'repulsionOff'}`,
      isHidden: !hasRolePeriSensorCommand,
      isLoading: isRepulsionCommandLoading,
      isDisabled:
        !isSectionActive(automaton, cabinet, section) ||
        sectionStatus === SensorStatusEnum.SLEEP ||
        !canSwitchRepulsion,
      onAction: () => handleSectionRepulsion(isRepulsionOff),
    },
    doubtRemoval: {
      label: 'contextmenu.actions.perimeterDoubtCheck',
      isHidden: currentContext !== ContextEnum.LIVE,
      isDisabled: !doubtCheckContextIsPresent,
      onAction: setupForPerimeterDoubtCheckContext,
    },
    displayPerimeterCamera: {
      label: 'contextmenu.actions.displayPerimeterCamera',
      isHidden: currentContext !== ContextEnum.DOUBT_PERIM,
      isDisabled: segmentWithCamera === selectedDoubtCheckSegmentName,
      onAction: openAttachedPerimeterCameras,
    },
    pointCUAS: {
      label: 'contextmenu.actions.pointCamera',
      isHidden: !hasAccessCamCommand,
      isDisabled: healthyLadCameras.length === 0 || !position,
      children: healthyLadCameras
        .toSorted((cam, cam2) => {
          const lowerCode = `${lowerSegment?.ladCameraAppCode}_${lowerSegment?.ladCameraCode}`;
          const upperCode = `${upperSegment?.ladCameraAppCode}_${upperSegment?.ladCameraCode}`;
          if (
            getSensorUniqueCode(cam) === upperCode ||
            (getSensorUniqueCode(cam) === lowerCode && getSensorUniqueCode(cam2) !== upperCode)
          ) {
            return -1;
          }
          return 1;
        })
        .reduce(
          (acc, current) => ({
            ...acc,
            [getSensorUniqueCode(current)]: {
              label: getContextMenuCameraLabel(current),
              shouldNotTranslate: true,
              onAction: () => handleLookAt(current),
            },
          }),
          {},
        ),
    },
    switchMaintenanceMode: {
      label: `contextmenu.actions.${
        isSectionInMaintenance(automaton, cabinet, section) ? 'maintenanceOff' : 'maintenanceOn'
      }`,
      isHidden: !hasRoleSensorMaintenance,
      isLoading: isMaintenanceLoading,
      isDisabled: automaton.maintenance || cabinet.maintenance || !isSectionActive(automaton, cabinet, section),
      onAction: () => handleSectionMaintenance(segments),
    },
    switchSectionConnection: {
      label: `contextmenu.actions.${
        isSectionActive(automaton, cabinet, section) ? 'disconnectSection' : 'reconnectSection'
      }`,
      isHidden: !hasRoleSensorMaintenance,
      isLoading: isActivationLoading,
      onAction: () => handleSectionActivation(segments),
    },
    modifySection: {
      label: 'contextmenu.actions.modifySection.title',
      isHidden: !hasRoleSensorMaintenance && !hasRolePeriSensorCommand,
      children: {
        upPart: {
          label: 'contextmenu.actions.modifySection.upPart',
          isDisabled: !upperSegment,
          children: {
            repulsionOn: {
              label: 'contextmenu.actions.modifySection.repulsionOn',
              isHidden: !hasRolePeriSensorCommand,
              isLoading: isRepulsionCommandLoading,
              isDisabled:
                !isSegmentActive(automaton, cabinet, upperSegment) ||
                sectionStatus === SensorStatusEnum.SLEEP ||
                !isUpperRepulsionOff,
              onAction: () => upperSegment && handleSegmentRepulsion(true, upperSegment),
            },
            repulsionOff: {
              label: 'contextmenu.actions.modifySection.repulsionOff',
              isHidden: !hasRolePeriSensorCommand,
              isLoading: isRepulsionCommandLoading,
              isDisabled:
                !isSegmentActive(automaton, cabinet, upperSegment) ||
                sectionStatus === SensorStatusEnum.SLEEP ||
                isUpperRepulsionOff,
              onAction: () => upperSegment && handleSegmentRepulsion(false, upperSegment),
            },
            switchMaintenanceMode: {
              label: `contextmenu.actions.${upperSegment?.maintenance ? 'maintenanceOff' : 'maintenanceOn'}`,
              isHidden: !hasRoleSensorMaintenance,
              isLoading: isMaintenanceLoading,
              isDisabled:
                !isSegmentActive(automaton, cabinet, upperSegment) || automaton.maintenance || cabinet.maintenance,
              onAction: () => upperSegment && handleSectionMaintenance([upperSegment]),
            },
            switchSegmentConnection: {
              label: `contextmenu.actions.modifySection.${
                upperSegment?.active ? 'disconnectSegment' : 'reconnectSegment'
              }`,
              isHidden: !hasRoleSensorMaintenance,
              isLoading: isActivationLoading,
              onAction: () => upperSegment && handleSectionActivation([upperSegment]),
            },
          },
        },
        bottomPart: {
          label: 'contextmenu.actions.modifySection.bottomPart',
          isDisabled: !lowerSegment,
          children: {
            repulsionOn: {
              label: 'contextmenu.actions.modifySection.repulsionOn',
              isHidden: !hasRolePeriSensorCommand,
              isLoading: isRepulsionCommandLoading,
              isDisabled:
                !isSegmentActive(automaton, cabinet, lowerSegment) ||
                sectionStatus === SensorStatusEnum.SLEEP ||
                !isLowerRepulsionOff,
              onAction: () => lowerSegment && handleSegmentRepulsion(true, lowerSegment),
            },
            repulsionOff: {
              label: 'contextmenu.actions.modifySection.repulsionOff',
              isHidden: !hasRolePeriSensorCommand,
              isLoading: isRepulsionCommandLoading,
              isDisabled:
                !isSegmentActive(automaton, cabinet, lowerSegment) ||
                sectionStatus === SensorStatusEnum.SLEEP ||
                isLowerRepulsionOff,
              onAction: () => lowerSegment && handleSegmentRepulsion(false, lowerSegment),
            },
            switchMaintenanceMode: {
              label: `contextmenu.actions.${lowerSegment?.maintenance ? 'maintenanceOff' : 'maintenanceOn'}`,
              isHidden: !hasRoleSensorMaintenance,
              isLoading: isMaintenanceLoading,
              isDisabled:
                !isSegmentActive(automaton, cabinet, lowerSegment) || automaton.maintenance || cabinet.maintenance,
              onAction: () => lowerSegment && handleSectionMaintenance([lowerSegment]),
            },
            switchSegmentConnection: {
              label: `contextmenu.actions.modifySection.${
                lowerSegment?.active ? 'disconnectSegment' : 'reconnectSegment'
              }`,
              isHidden: !hasRoleSensorMaintenance,
              isLoading: isActivationLoading,
              onAction: () => lowerSegment && handleSectionActivation([lowerSegment]),
            },
          },
        },
      },
    },
    ...commonMenuItems,
  };

  return (
    <VStack gap={0}>
      <MenuList menuItems={menuItems} depth={0} selectedOptions={selectedOptions} handleClick={handleClick} />
    </VStack>
  );
}
