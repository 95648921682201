import { VStack } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import CustomCheckbox from '@components/common/inputs/CustomCheckbox';
import { TypeEnum } from '@utils/sensors/configuration.constants';
import { Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';

import { MonitoringState } from '../list/MonitoringList';

type Props = {
  selectedTypes: Set<TypeEnum>;
  types: Set<TypeEnum>;
  setState: Dispatch<Partial<MonitoringState>>;
};

function TypeFilter({ selectedTypes, types, setState }: Readonly<Props>) {
  const allType = selectedTypes.size === types.size;

  return (
    <VStack gap={2} padding={2} alignItems="start" width="100%" backgroundColor="neutral.800">
      <Text fontWeight="medium" color="neutral.300">
        <FormattedMessage id="components.monitoring.filter.categories" />
      </Text>
      <Text
        paddingX={1}
        paddingY="1px"
        border="2px solid"
        borderColor={allType ? 'neutral.600' : 'cyber.500'}
        color={allType ? 'neutral.500' : 'neutral.white'}
        cursor={allType ? 'default' : 'pointer'}
        noOfLines={1}
        onClick={() =>
          !allType &&
          setState({
            selectedTypes: types,
          })
        }
      >
        <FormattedMessage id="components.monitoring.filter.allCategories" />
      </Text>
      {Array.from(types).map((type) => (
        <CustomCheckbox
          isChecked={selectedTypes.has(type)}
          value={type}
          key={type}
          onChange={() => {
            const newSet = new Set(selectedTypes);
            if (selectedTypes.size === 1 && selectedTypes.has(type) && types.size !== 1) {
              newSet.delete(type);
              newSet.add(Array.from(types).filter((item) => item !== type)[0]);
            } else {
              newSet.has(type) ? newSet.delete(type) : newSet.add(type);
            }
            setState({
              selectedTypes: newSet,
            });
          }}
        >
          <Text marginLeft={1}>
            <FormattedMessage id={`components.monitoring.filter.${type}`} />
          </Text>
        </CustomCheckbox>
      ))}
    </VStack>
  );
}

export default TypeFilter;
