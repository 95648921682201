import { Text, VStack } from '@chakra-ui/layout';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { formatDate } from '@utils/date.utils';
import { formatCoordinatesToString } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { FormattedMessage, useIntl } from 'react-intl';

import { DateTimeEnum } from '@/types/dateTime.types';
import { ReplayEvent } from '@/types/replay/replay.types';

type Props = {
  event: ReplayEvent;
};

export default function EventTooltip({ event }: Readonly<Props>) {
  const userSettingsUnit = useAppSelector(selectCoordinatesUnit);
  const coordinates = new DmsCoordinates(event.position?.latitude ?? 0, event.position?.longitude ?? 0);
  const { formatMessage } = useIntl();
  return (
    <VStack marginTop="4px" spacing="0px" align="start" fontWeight="medium" fontSize="14px" lineHeight="17px">
      <Text fontWeight="700">{event.name}</Text>
      <FormattedMessage
        id="replay.timeline.eventTimeInfo"
        values={{
          date: formatDate(event.startDate, DateTimeEnum.DATE),
          time: formatDate(event.startDate, DateTimeEnum.TIME),
          duration: event.duration,
        }}
      />
      <Text>
        {formatMessage({ id: 'global.At' })} {formatCoordinatesToString(coordinates, userSettingsUnit)}
      </Text>
    </VStack>
  );
}
