import { Text, VStack } from '@chakra-ui/layout';
import { useAppSelector } from '@hooks/redux.hooks';
import { useSelectorWithReplayMode } from '@hooks/useSelectorWithReplayMode';
import { selectCoordinatesUnit } from '@redux/settings/settings.selectors';
import { selectTrackFromRawSituationByTrackLights } from '@redux/situation/situation.selectors';
import { getRoundedValue } from '@utils/math.utils';
import { getTargetInfoId, getTrackUniqueId } from '@utils/target.utils';
import { convertSpeedUnitFromMPerS, formatUnit, SpeedUnits } from '@utils/unit.utils';
import { formatCoordinatesToString } from '@utils/validation/coordinates.utils';
import DmsCoordinates from 'dms-conversion';
import { FormattedMessage } from 'react-intl';

import { Target } from '@/types/c2/c2.types';
import { FeatureType, FeatureTypeEnum } from '@/types/map.types';
import { IdentificationEnum } from '@/types/sensor/identification.types';
import { AbstractLocation, PlanarLocation } from '@/types/sensor/sensor.types';

export interface TargetTooltipData extends FeatureType {
  type: FeatureTypeEnum.TARGET_ICON;
  data: Target<AbstractLocation>;
}

function TargetTooltip(props: Readonly<TargetTooltipData>) {
  const { id, identification, classification, lastPosition, trackIds } = props.data;
  const coordinatesUnit = useAppSelector(selectCoordinatesUnit);

  const tracks = useSelectorWithReplayMode(selectTrackFromRawSituationByTrackLights, trackIds.map(getTrackUniqueId));

  const sensorCodes = trackIds.map((sensor) => sensor.sensorCode).join(', ');

  return (
    <VStack gap={0.5} alignItems="flex-start">
      <Text fontSize="16px">{getTargetInfoId(tracks, identification.mainIdentification, id)}</Text>
      <Text fontSize="16px">
        <FormattedMessage id={`target.identification.${identification.mainIdentification}`} />
      </Text>
      <Text fontSize="16px">
        <FormattedMessage id={`target.classification.${classification.classification}`} />
      </Text>
      {lastPosition !== null && (
        <Text fontSize="16px">
          {formatCoordinatesToString(
            new DmsCoordinates(
              (lastPosition.location as PlanarLocation).position?.latitude as number,
              (lastPosition.location as PlanarLocation).position?.longitude as number,
            ),
            coordinatesUnit,
          )}
        </Text>
      )}
      {lastPosition !== null &&
        (identification.mainIdentification === IdentificationEnum.DRONE ||
          identification.mainIdentification === IdentificationEnum.AIRCRAFT) && (
          <Text fontSize="16px">
            {formatUnit(
              SpeedUnits.KNOTS,
              getRoundedValue(
                convertSpeedUnitFromMPerS(SpeedUnits.KNOTS, (lastPosition.location as PlanarLocation).speed, 0),
              ),
            )}{' '}
            (
            {formatUnit(
              SpeedUnits.KILOMETERS_PER_HOUR,
              getRoundedValue(
                convertSpeedUnitFromMPerS(
                  SpeedUnits.KILOMETERS_PER_HOUR,
                  (lastPosition.location as PlanarLocation).speed,
                  0,
                ),
              ),
            )}
            )
          </Text>
        )}
      <Text fontSize="16px">{sensorCodes}</Text>
    </VStack>
  );
}

export default TargetTooltip;
