import '@components/common/layout/scrollbar.scss';

import { Box } from '@chakra-ui/react';
import { CSSProperties, Dispatch, memo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { AlertsState } from './AlertsPanel';
import BaseAlertCard from './cards/BaseAlertCard';

type Props = {
  alertIds: number[];
  state: AlertsState;
  setState: Dispatch<AlertsState>;
};

function LiveAlertList({ alertIds, state, setState }: Readonly<Props>) {
  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    return <BaseAlertCard style={style} alertId={alertIds[index]} state={state} setState={setState} />;
  };

  return (
    <AutoSizer style={{ width: '100%', height: '100%', position: 'relative' }} disableWidth>
      {({ height }) => {
        const totalListHeight = alertIds.length * 122;
        const isOverflowing = totalListHeight > height;
        return (
          <>
            <FixedSizeList
              width="100%"
              itemKey={(index) => alertIds[index]}
              height={isOverflowing ? height : totalListHeight}
              itemCount={alertIds.length}
              style={{ flexShrink: 0 }}
              itemSize={122}
              className="cockpit-scrollbar"
            >
              {Row}
            </FixedSizeList>
            <Box
              position="absolute"
              backgroundColor="neutral.800-op70"
              top={totalListHeight}
              marginTop={0.25}
              height={height - totalListHeight - 2}
              width="100%"
            />
          </>
        );
      }}
    </AutoSizer>
  );
}

export default memo(LiveAlertList, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
