import { Box } from '@chakra-ui/react';
import { useAppSelector } from '@hooks/redux.hooks';
import { selectIsCriticalAlertNotAck } from '@redux/situation/situation.selectors';

export default function CockpitBackground() {
  const isCritical = useAppSelector(selectIsCriticalAlertNotAck);

  const backgroundGif = isCritical ? '/cockpit-animated-background-panic.gif' : '/cockpit-animated-background.gif';

  return (
    <Box
      width="100%"
      height="100%"
      position="absolute"
      backgroundImage={`url(${backgroundGif})`}
      backgroundSize="cover"
      opacity={isCritical ? 1 : 0.5}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    />
  );
}
